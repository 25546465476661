import axios from 'axios';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Http from '../Http'
import { redirect, useNavigate } from 'react-router';
import getUser from './Getuser';
import { Link ,useParams} from 'react-router-dom';
import Geolocation from 'geolocation';
import LogoLoader from './loader';


const ChangePassword = () => {
    const [user] = useState(getUser());
    const [loading, setLoading] = useState(false); // Add loading state
    const [isLoading, setIsLoading] = useState(true);
    const userId = useParams();

    const [email, userEmail] = useState('');
    const [password, userErpass] = useState('');
    const [s_code, setSecure] = useState('');
    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);
    useEffect(() => {
      
        userEmail(userId.email);
      }, [userId]);
    const validate = () => {
        let result = true;
        if (email === '' || email === null) {
            result = false
            // toast.warning('please enter the email');
            toast('please enter the email');
        }
        if (password === '' || password === null) {
            result = false
            // toast.warning('please enter the email');
            toast('please enter the email');
        }
        if (s_code === '' || s_code === null) {
            result = false
            // toast.warning('please enter the email');
            toast('please enter the email');
        }

    }
    const Proccesslogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const headers = {
            'Content-Type': 'application/json',
        };

        try {
            const res = await axios.post('https://api.passmh.com/api/change_password', {
                email:userId.email,
                token:userId.token,
                s_code:s_code,
                password:password,
            }, { headers });
            if (res.data.message === "Password Changed Successfully") {
                
                toast.success(res.data.message, {
                    onClose: () => window.location.replace('/login'), // Reload the page when the toast is closed
                });
                setTimeout(() => {
                    window.location.replace('/login'); // Reload the page after a delay
                }, 1500);
                
            } else {
                toast.warning(res.data.message);
            }
        } catch (error) {
            console.error('Error', error);
        }
        finally {
            setLoading(false);
        }
    }
    //loction


    return (
        <div className="content-wrapper" >
            {user ? (
                window.location.replace('/home')


            ) : (

                <section className="content " style={{ paddingTop: '50px' }}>
                    {
                        isLoading ? <LogoLoader /> : (
                            <div className="container-fluid">
                                <div className="row " >
                                    <div className="col-md-6 " style={{ margin: 'auto' }}>
                                        <div className="card text-center">
                                            <div className="card-header text-center " style={{ background: '#223367', color: '#fff' }}>
                                                <h4 className=" text-center" style={{ float: 'center' }}> PassMH Change Password  </h4>
                                            </div>
                                            <div className="card-body login-card-body p-4 ">
                                                <p className="login-box-msg"> <img src={window.location.origin + '/passpm.png'} width={'100'} /> </p>
                                                <form onSubmit={Proccesslogin} method='post'>
                                                    <div className="input-group mb-3 ">
                                                        <input type="email" name="email" className="form-control" placeholder=" Enter Email" value={email} onChange={e => userEmail(e.target.value)} />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-envelope" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input type="password" name="" className="form-control" placeholder="Secure Code" value={s_code} onChange={e => setSecure(e.target.value)} />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-lock" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input type="password" name="" className="form-control" placeholder="New Password" value={password} onChange={e => userErpass(e.target.value)} />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-lock" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4">

                                                        {/* /.col */}
                                                        <div className="col-md-4">
                                                            <button type="submit" className="btn btn-block text-white" style={{ background: '#223367' }}> <i className='fa fa-save'></i>  Change Password</button>
                                                        </div>
                                                        {/* /.col */}
                                                    </div>
                                                </form>


                                            </div>

                                        </div>

                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                        )
                    }
                    {/* /.container-fluid */}
                </section>
            )}

            <ToastContainer />

        </div>

    );
}
export default ChangePassword;