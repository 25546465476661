import React from "react";

import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";

import { ToastContainer, toast } from 'react-toastify';

const Policy = () => {
    return (
        <>
            <div className="main" >
                {/* Navigation Bar Two*/}
                <NavbarTwo />
                <div className="container">
                    <div className="row col-md-12 mt-4 mb-4" style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
                        <h4 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'PassMH Privacy Policy' : ' سياسة خصوصية بصمة '
                            }
                        </h4>
                        <p className="col-md-10 mt-2 mb-2">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.' : 'تصف سياسة الخصوصية هذه سياساتنا وإجراءاتها بشأن الجمع واالستخدام واإلفصاح عن معلوماتك عند استخدامك للخدمة وإخبارك عنها حقوق الخصوصية الخاصة بك وكيف يحميك القانون. '
                            }
                        </p>
                        <p className="col-md-10 mt-2 mb-2">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.' : 'نستخدم بياناتك الشخصية لتوفير الخدمة وتحسينها. باستخدامك لهذه الخدمة )بصمة(، أنت توافق على جمع واستخدام المعلومات ا وفق لـ سياسة الخصوصية هذه. '
                            }
                        </p>
                        <h4 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Interpretation and Definitions' : ' التفسير والتعريفات'
                            }
                        </h4>
                        <h5 className="col-md-10 mt-2 mb-2" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Interpretation' : 'تفسير '
                            }
                        </h5>
                        <p className="col-md-10 mt-2 mb-2" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.' : 'يجب أن يكون للتعريفات التالية نفس المعنى بغض النظر عما إذا كانت تظهر بصيغة المفرد أو الجمع.'
                            }
                        </p>
                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Definitions' : '  تعريفات'
                            }
                        </h5>
                        <p className="col-md-10 mt-2 mb-2 text-dark" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'For the purposes of this Privacy Policy:' : 'ألغراض سياسة الخصوصية هذه:'
                            }
                        </p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Account means a unique account created for You to access our Service or parts of our Service.' : ' الحساب يعني حساًبا فريًدا تم إنشاؤه لتتمكن من الوصول إلى خدمتنا أو أجزاء من خدمتنا.'
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to PassMH operator.' : ' الشركة يُشار إليها باسم الشركة أو نحن أو شركتنا في هذه الاتفاقية تشير إلى مشغل منصة بصمة (شركة سنيم لتقنية المعلومات).'
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.' : ' ملفات تعريف الارتباط هي ملفات صغيرة يتم وضعها في ملف جهاز كمبيوتر أو جهاز محمول'
                            }
                        </li></p>

                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Country refers to: Saudi Arabia' : '  الدولة إلى المملكة العربية السعودية'
                            }
                        </li></p>

                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.' : 'الجهاز يعني أي جهاز يمكنه الوصول إلى الخدمة مثل كجهاز كمبيوتر أو هاتف محمول أو جهاز لوحي رقمي.'
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Personal Data is any information that relates to an identified or identifiable individual.' : 'البيانات الشخصية هي أي معلومات تتعلق بملف فرد محدد أو يمكن التعرف عليه.'
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Service refers to the Website' : 'الخدمة إلى موقع الويب.'
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.' : 'مقدم الخدمة يُقصد به أي شخص طبيعي أو اعتباري يعالج البيانات نيابة عن الشركة. يشير إلى طرف ثالث الشركات أو الأفراد الذين توظفهم الشركة لتسهيل الخدمة ، لتقديم الخدمة نيابة عن الشركة ، لأداء الخدمات المتعلقة بالخدمة أو لمساعدة الشركة في التحليل كيف يتم استخدام الخدمة.'
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).' : 'بيانات الاستخدام تشير إلى البيانات التي يتم جمعها تلقائيًا ، تم إنشاؤها إما عن طريق استخدام الخدمة أو من الخدمة البنية التحتية نفسها (على سبيل المثال ، مدة زيارة الصفحة).'
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Website refers to PassMH, accessible from https://www.Passmh.com' : 'موقع الويب يشير إلى بصمة، ويمكن الوصول إليه من https://www.passmh.com'
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable' : '   أنت تعني الفرد الذي يصل إلى الخدمة أو يستخدمها ، أو الشركة أو الكيان القانوني الآخر الذي ينوب عنه هذا الفرد يقوم بالوصول إلى الخدمة أو استخدامها ، حسب الاقتضاء.  '
                            }
                        </li></p>
                        <h4 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Collecting and Using Your Personal Data' : ' جمع بياناتك الشخصية واستخدامها '
                            }
                        </h4>
                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Types of Data Collected' : ' أنواع البيانات المجمعة'
                            }
                        </h5>
                        <p className="text-dark">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Personal Data' : '  البيانات الشخصية   '
                            }
                        </p>
                        <p className="text-dark">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:' : ' أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا ببعض المعلومات بشكل شخصي معلومات التعريف التي يمكن استخدامها للاتصال أو التعرف عليك. شخصيا قد تتضمن معلومات التعريف ، على سبيل المثال لا الحصر:   '
                            }
                        </p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Email address' : 'عنوان البريد الإلكتروني'
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' First name and last name ' : 'الاسم الأول واسم العائلة '
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Phone Number ' : 'رقم الهاتف  '
                            }
                        </li></p>
                        <p className=""><li>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Usage Data ' : 'بيانات الاستخدام   '
                            }
                        </li></p>
                        <p className=""><li>
                        {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'User Location' : '     الموقع الجغرافي للمستخدم  '
                            }
                        </li></p>
                        
                        <p className="text-dark mb-2">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Usage Data is collected automatically when using the Service. ' : ' يتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة.'
                            }
                        </p>
                        <p className=" mt-4">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data." : 'قد تتضمن بيانات الاستخدام معلومات مثل بروتوكول الإنترنت الخاص بجهازك العنوان (مثل عنوان IP) ، ونوع المتصفح ، وإصدار المتصفح ، وصفحات الخدمة التي تزورها ، وقت وتاريخ زيارتك ، الوقت الذي تقضيه فيها الصفحات ومعرفات الجهاز الفريدة وبيانات التشخيص الأخرى.'
                            }
                        </p>
                        <p className=" mt-4">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data." : ' عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول ، يجوز لنا جمع بعض المعلومات تلقائيًا ، بما في ذلك ، على سبيل المثال لا الحصر ، نوع الهاتف المحمول الجهاز الذي تستخدمه ، المعرف الفريد لجهازك المحمول ، عنوان IP الخاص بهاتفك المحمول الجهاز ، نظام تشغيل هاتفك المحمول ، نوع متصفح الإنترنت على الهاتف المحمول أنت الاستخدام ومعرفات الجهاز الفريدة وبيانات التشخيص الأخرى. '
                            }
                        </p>
                        <p className=" mt-4">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device." : 'قد نجمع أيضًا المعلومات التي يرسلها متصفحك عندما تزور موقعنا الخدمة أو عند الوصول إلى الخدمة عن طريق أو من خلال جهاز محمول. '
                            }
                        </p>
                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Tracking Technologies and Cookies' : 'تقنيات التتبع وملفات تعريف الارتباط '
                            }
                        </h5>
                        <p className="text-dark mt-4">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:" : 'نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة لتتبع النشاط على موقعنا خدمة وتخزين بعض المعلومات. تقنيات التتبع المستخدمة هي منارات ، العلامات والنصوص لجمع المعلومات وتتبعها ولتحسينها وتحليلها خدمتنا. قد تشمل التقنيات التي نستخدمها: '
                            }
                        </p>
                        <p className=" mt-4">
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies." : ' ملفات تعريف الارتباط أو ملفات تعريف ارتباط المتصفح. ملف تعريف الارتباط هو ملف صغير يتم وضعه على جهازك. يمكنك توجيه متصفحك لرفض جميع ملفات تعريف الارتباط أو رفضها الإشارة إلى وقت إرسال ملف تعريف الارتباط. ومع ذلك ، إذا كنت لا تقبل ملفات تعريف الارتباط ، قد لا تتمكن من استخدام بعض أجزاء من خدمتنا. إلا إذا كان لديك تعديل إعدادات المستعرض الخاص بك بحيث يرفض ملفات تعريف الارتباط ، خدمتنا قد تستخدم ملفات تعريف الارتباط.'
                                }
                            </li>
                        </p>
                        <p className=" ">
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity)." : 'إشارات الويب. أقسام معينة من خدمتنا ورسائل البريد الإلكتروني الخاصة بنا قد تحتوي على ملفات إلكترونية صغيرة تُعرف باسم إشارات الويب (يشار إليها أيضًا باسم gifs الواضحة ، وعلامات البكسل ، وصور gifs أحادية البكسل) التي تسمح للشركة ، لـ على سبيل المثال ، لحساب عدد المستخدمين الذين زاروا تلك الصفحات أو فتحوا بريدًا إلكترونيًا و لإحصائيات مواقع الويب الأخرى ذات الصلة (على سبيل المثال ، تسجيل الشعبية قسم معين والتحقق من سلامة النظام والخادم).'
                                }
                            </li>
                        </p>
                        <p className="text-dark mt-4">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: What Are Cookies?.' : 'يمكن أن تكون ملفات تعريف الارتباط ثابتة أو مستمرة تظل ملفات تعريف الارتباط على جهاز الكمبيوتر الشخصي أو الجهاز المحمول الخاص بك عندما تكون غير متصل بالإنترنت ، بينما يتم حذف ملفات تعريف الارتباط الخاصة بالجلسة بمجرد إغلاق متصفح الويب الخاص بك.'
                            }
                        </p>
                        <p className="text-dark mt-4">
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'We use both Session and Persistent Cookies for the purposes set out below:' : 'نستخدم كلاً من ملفات تعريف الارتباط الخاصة بالجلسة وملفات تعريف الارتباط الدائمة للأغراض الموضحة أدناه:'
                            }
                        </p>
                        <p className="mt-4">
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Necessary / Essential Cookies Type: Session Cookies Administered by: Us Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.' : 'ملفات تعريف الارتباط الضرورية /الأساسية النوع: ملفات تعريف ارتباط الجلسة الغرض: ملفات تعريف الارتباط هذه ضرورية لتزويدك بالخدمات متاح من خلال الموقع الإلكتروني ولتمكينك من استخدام بعض منها الميزات. إنها تساعد على مصادقة المستخدمين ومنع الاستخدام الاحتيالي لـ حسابات المستخدمين. بدون ملفات تعريف الارتباط هذه ، الخدمات التي طلبتها لا يمكن توفيره ، ونحن نستخدم ملفات تعريف الارتباط هذه فقط لتزويدك مع هذه الخدمات.'
                                }
                            </li>
                        </p>
                        <p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Cookies Policy / Notice Acceptance Cookies Type: Persistent Cookies Administered by: Us Purpose: These Cookies identify if users have accepted the use of cookies on the Website' : 'سياسة ملفات تعريف الارتباط /ملفات تعريف ارتباط قبول الإشعار النوع: ملفات تعريف الارتباط الدائمة الغرض: تحدد ملفات تعريف الارتباط هذه ما إذا كان المستخدمون قد وافقوا على استخدام ملفات تعريف الارتباط على الموقع.'
                                }
                            </li>
                        </p>
                        <p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Functionality Cookies' : 'ملفات تعريف الارتباط الوظيفية'
                                }
                            </li>
                        </p>
                        <p className=''>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Type: Persistent Cookies' : 'النوع: ملفات تعريف الارتباط الدائمة'
                            }

                        </p>
                        <p className=''>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Administered by: Us' : 'الغرض: تسمح لنا ملفات تعريف الارتباط هذه بتذكر الاختيارات التي تتخذها عند استخدامك'
                            }

                        </p>
                        <p className=''>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.' : 'لها موقع الويب ، مثل تذكر تفاصيل تسجيل الدخول أو اللغة تفضيل. الغرض من ملفات تعريف الارتباط هذه هو تزويدك بمزيد من تجربة شخصية ولتجنب الاضطرار إلى إعادة إدخال تفضيلاتك في كل مرة تستخدم فيها الموقع.'
                            }

                        </p>
                        <p className='text-dark mt-4'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.' : 'لمزيد من المعلومات حول ملفات تعريف الارتباط التي نستخدمها وخياراتك فيما يتعلق بملفات تعريف الارتباط ، يرجى زيارة سياسة ملفات تعريف الارتباط الخاصة بنا أو قسم ملفات تعريف الارتباط في سياسة الخصوصية الخاصة بنا.'
                            }

                        </p>
                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Use of Your Personal Data' : 'استخدام بياناتك الشخصية  '
                            }
                        </h5>
                        <p className='text-dark mt-4'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'The Company may use Personal Data for the following purposes:' : 'يجوز للشركة استخدام البيانات الشخصية للأغراض التالية:'
                            }

                        </p>
                        <p className=' mt-4'>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'To provide and maintain our Service, including to monitor the usage of our Service.' : ' لتقديم خدمتنا وصيانتها ، بما في ذلك مراقبة استخدام خدمتنا. '
                                }
                            </li>


                        </p>
                        <p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.' : ' لإدارة حسابك: لإدارة تسجيلك كملف مستخدم الخدمة. يمكن أن تمنحك البيانات الشخصية التي تقدمها حق الوصول إلى الوظائف المختلفة للخدمة المتاحة لك مستخدم مسجل. '
                                }
                            </li>
                        </p>
                        < p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.' : ' من أجل تنفيذ عقد: التطوير ، الامتثال والتعهد بعقد شراء المنتجات ، العناصر أو الخدمات التي اشتريتها أو أي عقد آخر معنا من خلال الخدمة. '
                                }
                            </li>
                        </p>
                        < p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation." : ' للاتصال بك: للاتصال بك عبر البريد الإلكتروني أو الهاتف المكالمات أو الرسائل القصيرة أو غيرها من أشكال الاتصالات الإلكترونية المكافئة ، مثل كإخطارات دفع لتطبيق جوال بخصوص التحديثات أو الاتصالات الإعلامية المتعلقة بالوظائف أو المنتجات أو الخدمات المتعاقد عليها ، بما في ذلك التحديثات الأمنية ، عند الضرورة أو معقولة لتنفيذها.  '
                                }
                            </li>
                        </p>
                        < p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information." : 'لتزويدك بالأخبار والعروض الخاصة والعامة معلومات حول السلع والخدمات والأحداث الأخرى التي نقدمها تشبه تلك التي اشتريتها بالفعل أو استفسرت عنها ما لم تكن قد اخترت عدم تلقي هذه المعلومات.  '
                                }
                            </li>
                        </p>
                        < p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred." : 'لعمليات نقل الأعمال: قد نستخدم معلوماتك لـ تقييم أو إجراء عملية اندماج أو تصفية أو إعادة هيكلة ، إعادة التنظيم أو الحل أو غير ذلك من عمليات البيع أو النقل لبعض أو كل أصولنا ، سواء كانت منشأة مستمرة أو كجزء من الإفلاس ، التصفية ، أو الإجراءات المماثلة ، التي نحتفظ فيها بالبيانات الشخصية حول مستخدمي خدمتنا من بين الأصول المنقولة. '
                                }
                            </li>
                        </p>
                        < p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience." : 'لأغراض أخرى : قد نستخدم معلوماتك لـ أغراض أخرى ، مثل تحليل البيانات ، وتحديد اتجاهات الاستخدام ، تحديد فعالية حملاتنا الترويجية و تقييم وتحسين خدماتنا. '
                                }
                            </li>
                        </p>

                        < p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "To register attendance and departure through the user location." : ' لتسجيل الحضور والانصراف عن طريق موقع المستخدم. '
                                }
                            </li>
                        </p>

                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Retention of Your Personal Data' : ' الاحتفاظ ببياناتك الشخصية   '
                            }
                        </h5>
                        < p className='mt-4 text-dark'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "The Company will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies." : 'ستحتفظ الشركة ببياناتك الشخصية فقط طالما كان ذلك ضروريًا الأغراض المنصوص عليها في سياسة الخصوصية هذه. سوف نحتفظ بك ونستخدم ملفات البيانات الشخصية بالقدر اللازم للامتثال لالتزاماتنا القانونية (ل على سبيل المثال ، إذا كنا مطالبين بالاحتفاظ ببياناتك للامتثال للقوانين المعمول بها) ، حل النزاعات وفرض اتفاقياتنا وسياساتنا القانونية.'
                            }

                        </p>
                        < p className='mt-4 text-dark'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "The Company will also retain usage data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except this data is used to strengthen the security or to improve the functionality of the Service, or we are legally obligated to retain this data for longer periods." : 'ستحتفظ الشركة أيضًا ببيانات الاستخدام لأغراض التحليل الداخلي. إستعمال يتم الاحتفاظ بالبيانات عمومًا لفترة زمنية أقصر ، باستثناء هذه البيانات يستخدم لتعزيز الأمن أو لتحسين وظائف الخدمة ، أو نحن ملزمون قانونًا بالاحتفاظ بهذه البيانات لفترة أطول فترات.'
                            }

                        </p>
                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Transfer of Your Personal Data' : ' نقل بياناتك الشخصية  '
                            }
                        </h5>
                        < p className='mt-4 text-dark'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Your information, including personal data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. This means that this information may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction." : 'تم معالجة معلوماتك ، بما في ذلك البيانات الشخصية ، لدى الشركة مكاتب التشغيل وفي أي أماكن أخرى يكون فيها الأطراف المشاركة في تقع المعالجة. هذا يعني أنه يمكن نقل هذه المعلومات إلى - وتتم صيانتها على - أجهزة كمبيوتر موجودة خارج ولايتك أو مقاطعتك أو بلدك أو أي سلطة قضائية حكومية أخرى قد تختلف فيها قوانين حماية البيانات من تلك الموجودة في نطاق سلطتك القضائية'
                            }

                        </p>
                        < p className='mt-4 text-dark'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Your consent to this Privacy Policy followed by your submission of such information constitutes your consent to such transmission." : 'موافقتك على سياسة الخصوصية هذه متبوعة بإرسالك لهذا تمثل المعلومات موافقتك على هذا النقل.'
                            }

                        </p>
                        < p className='mt-4 text-dark'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "The Company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of ownership of your personal data will occur to an organization or country unless there are adequate controls in place including the security of your data and other personal information." : 'ستتخذ الشركة جميع الخطوات اللازمة بشكل معقول للتأكد من صحة بياناتك يتم التعامل معها بشكل آمن ووفقًا لسياسة الخصوصية هذه وعدم نقل ملكية سيتم نقل بياناتك الشخصية إلى منظمة أو دولة ما لم يكن هناك هي ضوابط كافية مطبقة بما في ذلك أمن بياناتك وغيرها المعلومات الشخصية.'
                            }

                        </p>
                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Disclosure of your personal data' : 'الكشف عن بياناتك الشخصية     '
                            }
                        </h5>

                        < p className='mt-4 text-dark'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "We do not disclose any of your information to any third parties unless this is in compliance with the law and judicial authorities." : 'لا نقوم بالكشف عن أي من معلوماتك لأي جهات أخرى مالم يكن ذلك في نطاق الامتثال للقانون وللسلطات القضائية.'
                            }

                        </p>
                        < p className='mt-4 text-dark'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Law enforcement" : 'تطبيق القانون'
                            }

                        </p>
                        < p className='mt-4 text-dark'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency)." : 'في ظل ظروف معينة ، قد يُطلب من الشركة الكشف عن البيانات الشخصية إذا كان ذلك مطلوبًا بموجب القانون أو استجابة لطلبات صالحة من قبل السلطات العامة (مثل محكمة أو وكالة حكومية).'
                            }

                        </p>
                        < p className='mt-4 text-dark'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Other legal requirements" : 'المتطلبات القانونية الأخرى'
                            }

                        </p>
                        < p className='mt-4 text-dark'>

                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:" : 'قد تفصح الشركة عن بياناتك الشخصية بحسن نية من أجل:'
                            }

                        </p>
                        < p className='mt-4'>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Comply with a legal obligation" : 'الامتثال لالتزام قانوني'
                                }
                            </li>
                        </p>
                        <p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Protect and defend the rights or property of the Company" : 'حماية والدفاع عن حقوق أو ممتلكات الشركة'
                                }
                            </li>
                        </p>
                        <p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? " Prevent or investigate possible wrongdoing in connection with the Service" : 'منع المخالفات المحتملة المتعلقة بالخدمة أو التحقيق فيها'
                                }
                            </li>
                        </p>
                        <p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? " Protect the personal safety of Users of the Service or the public" :'حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور'}
                            </li>
                        </p>
                        <p className=''>
                            <li>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? " Protect against legal liability" :'الحماية من المسؤولية القانونية'}
                            </li>
                        </p>
                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Security of Your Personal Data' : 'أمان بياناتك الشخصية  '
                            }
                        </h5>
                        <p className='mt-4 text-dark'>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security." :'يعد أمان بياناتك الشخصية أمرًا مهمًا بالنسبة لنا، ولكن تذكر أن طرق الاتصال عبر الإنترنت، أو طرق التخزين الإلكترونية قد لا تكون آمنة بنسبة 100٪. بينما نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك الشخصية، لا يمكننا ضمان أمنها المطلق والدائم.'}
                        </p>

                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Children's Privacy" : 'خصوصية الأطفال '
                            }
                        </h5>
                        <p className='mt-4 text-dark'>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers" :'لا تخاطب خدمتنا أي شخص يقل عمره عن 13 عامًا. نحن لا ندري ذلك جمع معلومات التعريف الشخصية من أي شخص دون سن 13. إذا أنت أحد الوالدين أو الوصي وتدرك أن طفلك قد قدم لنا مع البيانات الشخصية ، يرجى الاتصال بنا. إذا علمنا أننا قد جمعنا البيانات الشخصية من أي شخص يقل عمره عن 13 عامًا دون التحقق من الوالدين الموافقة ، نحن نتخذ خطوات لإزالة تلك المعلومات من خوادمنا.'}
                        </p>
                        <p className='mt-4 text-dark'>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information." :'إذا احتجنا إلى الاعتماد على الموافقة كأساس قانوني لمعالجة معلوماتك يتطلب بلدك موافقة أحد الوالدين ، وقد نطلب موافقة والديك قبل أن نجمع هذه المعلومات ونستخدمها.'}
                        </p>

                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Links to Other Websites" : 'روابط لمواقع أخرى'
                            }
                        </h5>
                        <p className='mt-4 text-dark'>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit." :'قد تحتوي خدمتنا على روابط لمواقع أخرى لا نقوم بتشغيلها. لو إذا قمت بالنقر فوق ارتباط جهة خارجية ، فسيتم توجيهك إلى هذا الطرف الثالث موقع. ننصحك بشدة بمراجعة سياسة الخصوصية لكل موقع أنت زيارة.'}
                        </p>
                        <p className='mt-4 text-dark'>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services." :'ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية عن المحتوى والخصوصية سياسات أو ممارسات أي مواقع أو خدمات خاصة بطرف ثالث.'}
                        </p>
                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Changes to this Privacy Policy" : 'التغييرات في سياسة الخصوصية هذه'
                            }
                        </h5>
                        <p className='mt-4 text-dark'>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page." :'قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سوف نخطرك بأي التغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة.'}
                        </p>
                        <p className='mt-4 text-dark'>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy' :'سنخبرك عبر البريد الإلكتروني و /أو إشعار بارز في "خدمتنا" قبل يصبح التغيير ساري المفعول وتحديث آخر تحديث التاريخ في في الجزء العلوي من سياسة الخصوصية هذه.'}
                        </p>
                        <p className='mt-4 text-dark'>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page' :'يُنصح بمراجعة سياسة الخصوصية هذه بشكل دوري لمعرفة أي تغييرات. تسري التغييرات التي تطرأ على سياسة الخصوصية هذه عند نشرها على هذه الصفحة.'}
                        </p>
                        <h5 className="mt-4 mb-4" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                            {
                                localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "Contact Us" : 'تواصل معنا'
                            }
                        </h5>
                        <p className='mt-4 text-dark'>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'If you have any questions about this Privacy Policy, You can contact us:' :'لا تتردد بالتواصل معنا لأي استفسار حول السياسة والخصوصية:'}
                        </p>
                        <p className='mt-4 text-dark mb-4'>
                                {
                                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'By email: info@Passmh.com' :'البريد الالكتروني: info@Passmh.com'}
                        </p>
                    </div>
                </div>



                {/* Footer Two */}
                <FooterTwo />
            </div>
            <ToastContainer />

        </>
    );
};

export default Policy;
