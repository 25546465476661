import { useEffect, useState } from 'react';
import axios from 'axios';
import getUser from './Getuser';
import { ToastContainer, toast } from 'react-toastify';
import LogoLoader from './loader';
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const Department = () => {
    const [user] = useState(getUser());
    const [dept_name, userDept] = useState('');
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [editedData, setEditedData] = useState({});
    const token = localStorage.getItem('token');

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const handleEdit = (row) => {
        setEditedData(row);
        toggleModal();
    };

    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    //loader
    const [isLoading, setIsLoading] = useState(true);
    const validate = (dept_name) => {
        let result = true;
        if (dept_name === '' || dept_name === null) {
            result = false
            toast('please enter the dept name');
        }

        return result;

    }
    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 800);
    }, []);
    //loader
    const processSignup = async (e) => {
        e.preventDefault();

        const { dept_name } = e.target.elements;
        const mainUrl = 'https://api.passmh.com/api/add_department';
        if (validate(dept_name)) {
            try {
                const response = await axios.post(mainUrl, {
                    dept_name: dept_name.value,

                }, { headers });

                if (response.data.message == "Department Added") {
                    toast.success('Department Has Been Added', {
                        onClose: () => window.location.reload(), // Reload the page when the toast is closed
                    });
                    setTimeout(() => {
                        window.location.reload(); // Reload the page after a delay
                    }, 1500);
                } else {
                    toast.warning(response.data.message);
                }

            } catch (error) {
                toast(error.message);

                // Handle signup error, such as displaying an error message
            }
        }
    };

    const column = [

        {
            name: 'Date',
            selector: row => format(row.created_at, ' MMMM do yyyy'),
            sortable: true
        },
        {
            name: 'Department',
            selector: row => (<div><i className='fa fa-building' style={{color:'rgb(34, 51, 103)'}}></i> {row.dept_name}  </div>),
            sortable: true

        },
        {
            name: 'Members',
            selector: row => (<div><i className='fa fa-users' style={{color:'rgb(34, 51, 103)'}}></i> {countUsers(row) } Members</div>),
            sortable: true

        },

        {
            name: 'Edit',
            selector: (row) => row.id,
            cell: (row) => (
                <button onClick={() => handleEdit(row)} className='btn-sm p-1 bg-c' style={{ color: 'rgb(48, 80, 165)' }}> <i className='fa fa-edit text-white'></i></button>
            ),


        },
        {
            name: 'X',
            selector: (row) => row.id,
            cell: (row) => (
                <button onClick={() => handleDelete(row.id)} className='btn-sm  p-1  bg-c' style={{ color: 'rgb(241, 130, 38)' }}> <i className='fa fa-trash text-white '></i></button>


            ),
        },
    ]
    const fetchData = async () => {
        try {
            const response = await axios.get('https://api.passmh.com/api/all_departments', { headers });
            console.log(response.data.data);
            setData(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

  

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data
        .filter((row) =>
            Object.values(row).some(
                (value) =>
                    value &&
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText.toLowerCase())
            )
        )
        .map((row) => ({ ...row }));

        const countUsers = (data) => {
            let count = 0;
            data.department_assign.forEach(assign => {
              count += assign.user ? 1: 0;
            });
            return count;
          };
    //delete
    const handleDelete = async (id) => {
        try {
            const res = await axios.delete(`https://api.passmh.com/api/delete_department/${id}`, { headers });
            // Remove the deleted task from the table
            toast.success(res.data.message, {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 1500);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };
    //edit
    const handleSave = async (e) => {
        e.preventDefault();
        var ids = editedData.id;
        try {
            // Make an API call to update the data in the database
            const res = await axios.post(`https://api.passmh.com/api/update_department/${ids}`, {
                dept_name: editedData.dept_name,
            }, { headers });
            // Update the corresponding row in the data table
            setData((prevData) =>
                prevData.map((row) => (row.id === editedData.id ? editedData : row))
            );
            toast(res.data.message, {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 1000);
            toggleModal();
        } catch (error) {
            toast.error('Error updating data:', error);
            // Handle error
        }
    };
    return (

        <div className="content-wrapper bg-white" >
            {user ? (
                <div>
                    {user.role == 'Admin' || user.role == 'SuperAdmin' ? (
                        <div>
                            {isLoading ? <LogoLoader /> :
                                <div>
                                    <section className="content " style={{ paddingTop: '50px' }}>
                                        <div className="container-fluid">
                                            
                                            <div className="row"  >
                                                <div className="col-md-12 " style={{ margin: 'auto' }}>
                                                    <div className='col-md-6 text-left'>

                                                        <div className="card-body login-card-body p-4">
                                                            <h2 className='mt-4 mb-4'>Add Department </h2>
                                                            <form onSubmit={processSignup}>
                                                                <div className="input-group mb-3">
                                                                    <input type="text" name="dept_name" className="form-control border" placeholder="Enter the Department Name" value={dept_name} onChange={e => userDept(e.target.value)} required />

                                                                </div>

                                                                <div className="row text-left">

                                                                    {/* /.col */}
                                                                    <div className="col-md-4 text-left">
                                                                        <button type="submit" className="btn btn-block text-white " style={{ background: '#223367' }}><i className='fa fa-plus-circle'></i> Save Department</button>
                                                                    </div>
                                                                    {/* /.col */}
                                                                </div>
                                                            </form>


                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="card mt-4">
                                                    <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                                                        <h3 className="card-title " style={{ fontSize: '18px' }}>ALL Departments </h3>
                                                    </div>
                                                    {/* /.card-header */}
                                                    <div className="card-body" >
                                                        <div className="card-body" >
                                                            <input
                                                                type="text"
                                                                placeholder="Search..."
                                                                value={searchText}
                                                                onChange={handleSearch}
                                                            />
                                                            <DataTable
                                                                columns={column}
                                                                data={filteredData}
                                                                pagination
                                                            >

                                                            </DataTable>


                                                            <Modal isOpen={modalOpen} toggle={toggleModal} size='lg'>
                                                                <ModalHeader toggle={toggleModal}>Update Department </ModalHeader>
                                                                <ModalBody>
                                                                    <form onSubmit={handleSave}>
                                                                        <div className="input-group mb-3">
                                                                            <input type="text" name="dept_name" className="form-control border" placeholder="Enter the Department Name"

                                                                                value={editedData.dept_name}
                                                                                onChange={(e) =>
                                                                                    setEditedData((prevData) => ({
                                                                                        ...prevData,
                                                                                        dept_name: e.target.value
                                                                                    }))
                                                                                }
                                                                                required />

                                                                        </div>

                                                                        <div className="row text-left">

                                                                            {/* /.col */}
                                                                            <div className="col-md-4 text-left">
                                                                                <button type="submit" className="btn btn-block text-white " style={{ background: '#223367' }}><i className='fa fa-plus-circle'></i> Update Department</button>
                                                                            </div>
                                                                            {/* /.col */}
                                                                        </div>
                                                                    </form>

                                                                </ModalBody>
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                    {/* /.card-body */}
                                                </div>
                                                {/* /.col */}
                                            </div>
                                            {/* /.row */}
                                        </div>
                                        {/* /.container-fluid */}
                                    </section>

                                </div>


                            }

                        </div>
                    ) : (
                        <div>
							<h2 style={{ margin: ' auto' }}>Welcome {user.name}</h2>
						</div>
             
            )}
                </div>


            ) : (
                window.location.replace('/login')

            )
            }

            <ToastContainer />

        </div >

    );
}
export default Department;