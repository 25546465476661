
import axios from 'axios';
import React, { useState, Link } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import getUser from './Getuser';
import LogoLoader from './loader';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useParams } from 'react-router-dom';
import { format, parseISO, getDay, set } from 'date-fns';

const Activity_details = () => {
    const [user] = useState(getUser());
    const [isLoading, setIsLoading] = useState(true);
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [request_user, setRequestUser] = useState(null);
    const [data, setData] = useState([]);
    const [note, setNote] = useState([]);
    const [job_list, setJobList] = useState([]);
    const [entry_type, setEntryType] = useState(null);
    const [entry_time, setEntryTime] = useState(null);
    const [activity_start_time, SetStart_time] = useState(null);
    const [activity_end_time, setEnd_time] = useState(null);
    const dateFormat = 'yyyy-MM-dd';
    const userId = useParams();
    const token = localStorage.getItem('token');
    const [modalOpen, setModalOpen] = useState(false);

    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        fetechAllUsers(userId);
    }, [userId]);
    const fetechAllUsers = async (userId) => {


        try {
            const response = await axios.post('https://api.passmh.com/api/get_date_id_wise_entry', {
                user_id: userId.id,
                date: userId.date
            },
                { headers });
            if (response.data.message === "activity lists") {
                console.log([response.data.message, response.data.data, response.data.requested_user]);
                setData(response.data.data);
            }
            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }


    useEffect(() => {
        fetechAllUsersNote(userId);
    }, [userId]);
    const fetechAllUsersNote = async (userId) => {


        try {
            const response = await axios.post('https://api.passmh.com/api/get_date_id_note', {
                user_id: userId.id,
                date: userId.date
            },
                { headers });
            if (response.data.message === "Note lists") {
                setNote(response.data.data);
            }
            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }


    useEffect(() => {
        fetechAllUsersJob(userId);
    }, [userId]);
    const fetechAllUsersJob = async (userId) => {


        try {
            const response = await axios.post('https://api.passmh.com/api/get_date_id_assign_job', {
                user_id: userId.id,
                date: userId.date
            },
                { headers });
            if (response.data.message === "Job lists") {
                setJobList(response.data.data);
            }
            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    const handleDeleteEntry = async (id) => {
        try {
            await axios.delete(`https://api.passmh.com/api/delete_entry/${id}`, { headers });
            // Remove the deleted task from the table
            toast.success('Deleted Entry', {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 1500);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };
    const handleDeleteNote = async (id) => {
        try {
            await axios.delete(`https://api.passmh.com/api/delete_Note/${id}`, { headers });
            // Remove the deleted task from the table
            toast.success('Deleted Note', {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 1500);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };
    const handleDeleteJob = async (id) => {
        try {
            await axios.delete(`https://api.passmh.com/api/delete_job/${id}`, { headers });
            // Remove the deleted task from the table
            toast.success('Job Deleted', {
                onClose: () => window.location.reload(), // Reload the page when the toast is closed
            });
            setTimeout(() => {
                window.location.reload(); // Reload the page after a delay
            }, 1500);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };
    const AddEntry = async () => {
        toggleModal();

    };
    return (
        <div className="content-wrapper bg-white" >
            {user ? (
                <div>
                    {isLoading ? <LogoLoader /> : (
                        <section className="content ">
                            <div className="container-fluid">
                                <div className="row " >
                                    <div className="col-md-12 mt-4">
                                        <div className="col-md-12 mt-4 mb-4">
                                            <h5 className=''>
                                                Activities Details For ({format(parseISO(userId.date), 'MMMM  d, yyyy')})
                                            </h5>


                                        </div>
                                        <div className="card-body table-responsive p-2  " >
                                            <div className="col-md-12 mt-4 mb-4 text-left">
                                                <h5 className=' text-bold' style={{ color: 'rgb(34, 51, 103)' }}>
                                                    <i className="fa fa-clock"></i>   Activities List
                                                </h5>
                                            </div>
                                            {/* <h6 className=' text-left text-bold mt-4 mb-4' onClick={() => AddEntry()} style={{ color: 'rgb(34, 51, 103)', cursor: 'pointer' }}>
                                                <i className="fa fa-plus-circle "></i> Add
                                            </h6> */}
                                            <table class="table text-left ml-2" style={{ fontWeight: '500' }} >
                                                <thead style={{ background: 'rgb(234, 235, 238)', padding: '0' }}>
                                                    <tr className=''>
                                                        <th scope="col ">CHECK IN </th>
                                                        <th scope="col">CLOCK OUT</th>

                                                        <th scope="col">BREAK START</th>
                                                        <th scope="col">BREAK END</th>
                                                        <th scope="col">JOB SITE</th>
                                                        <th scope="col">X</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ color: 'rgb(34, 51, 103)' }}>

                                                    {data.map((row) => {

                                                        return (
                                                            <tr key={row.id}  >


                                                                <td>

                                                                    {row.clockIn ? format(row.clockIn, 'h:mm a') : '---'}
                                                                </td>
                                                                <td >

                                                                    {row.clockOut ? format(row.clockOut, 'h:mm a') : '---'}
                                                                </td>

                                                                <td>

                                                                    {row.break_time_start ? format(row.break_time_start, 'h:mm a') : '---'}

                                                                </td>
                                                                <td>
                                                                    {row.break_time_end ? format(row.break_time_end, 'h:mm a') : '---'}
                                                                </td>
                                                                <td>
                                                                    <i className="fa fa-map-marker mr-1"></i>
                                                                    {row.site_name ? row.site_name : '---'}
                                                                </td>
                                                                {
                                                                    user.id == row.user_id ?
                                                                        <td>
                                                                            <a style={{ cursor: 'pointer' }} className=' btn-sm bg-c' onClick={() => handleDeleteEntry(row.id)}>
                                                                                <i className="fa fa-trash mr-1 text-white"></i>
                                                                            </a>
                                                                        </td>

                                                                        : ''
                                                                }



                                                            </tr>
                                                        );
                                                    }
                                                    )}


                                                </tbody>
                                            </table>

                                            <Modal isOpen={modalOpen} toggle={toggleModal} size='md'>
                                                <ModalHeader toggle={toggleModal}>Add Entry </ModalHeader>
                                                <ModalBody>
                                                    <form>
                                                        <div className='col-md-12'>
                                                            <select className='form-control' value={entry_type}
                                                                onChange={(e) => setEntryType(e.target.value)} >
                                                                <option>Select Entry Type</option>
                                                                <option value={'ClockIn'}  >
                                                                    Clock In
                                                                </option>
                                                                <option value={'ClockOut'}>
                                                                    Clock Out
                                                                </option>
                                                            </select>
                                                            <div className='col-md-12 mt-4'>
                                                                <div className='form-group'>
                                                                    <p>Start Time</p>
                                                                    <input type="time" className="form-control" palceholder="Start Time" value={entry_time}
                                                                        onChange={(e) => setEntryTime(e.target.value)} />
                                                                </div>

                                                            </div>

                                                            <div className='col-md-12 text-center'>
                                                                <button type='submit' className="bg-c"><i className='fa fa-save'></i> Save Entry</button>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </ModalBody>
                                            </Modal>
                                        </div>


                                        <div className="card-body table-responsive p-2  mt-4 " >
                                            <div className="col-md-12 mt-4 mb-4 text-left">
                                                <h5 className=' text-bold' style={{ color: 'rgb(34, 51, 103)' }}>
                                                    <i className="fa fa-clock"></i>   Note List
                                                </h5>
                                                {/* <h6 className=' text-left text-bold mt-4 mb-4' style={{ color: 'rgb(34, 51, 103)' }}>
                                                    <i className="fa fa-plus-circle"></i> Add
                                                </h6> */}
                                            </div>
                                            <table class="table text-left ml-2" style={{ fontWeight: '500' }} >
                                                <thead style={{ background: 'rgb(234, 235, 238)', padding: '0' }}>
                                                    <tr className=''>
                                                        <th scope="col ">Time </th>
                                                        <th scope="col">Note</th>
                                                        <th scope="col">X</th>


                                                    </tr>
                                                </thead>
                                                <tbody style={{ color: 'rgb(34, 51, 103)' }}>

                                                    {note.map((row) => {

                                                        return (
                                                            <tr key={row.id}  >


                                                                <td>

                                                                    {row.created_at ? format(row.created_at, 'h:mm a') : '---'}
                                                                </td>
                                                                <td >

                                                                    {
                                                                        row.note
                                                                    }
                                                                </td>
                                                                {
                                                                    user.id == row.user_id ?
                                                                        <td>
                                                                            <a style={{ cursor: 'pointer' }} className=' btn-sm bg-c' onClick={() => handleDeleteNote(row.id)}>
                                                                                <i className="fa fa-trash mr-1 text-white"></i>
                                                                            </a>
                                                                        </td>

                                                                        : ''
                                                                }




                                                            </tr>
                                                        );
                                                    }
                                                    )}


                                                </tbody>
                                            </table>


                                        </div>


                                        <div className="card-body table-responsive p-2  mt-4 " >
                                            <div className="col-md-12 mt-4 mb-4 text-left">
                                                <h5 className=' text-bold' style={{ color: 'rgb(34, 51, 103)' }}>
                                                    <i className="fa fa-clock"></i>  Added Jobs
                                                </h5>
                                                {/* <h6 className=' text-left text-bold mt-4 mb-4' style={{ color: 'rgb(34, 51, 103)' }}>
                                                    <i className="fa fa-plus-circle"></i> Add
                                                </h6> */}
                                            </div>
                                            <table class="table text-left ml-2" style={{ fontWeight: '500' }} >
                                                <thead style={{ background: 'rgb(234, 235, 238)', padding: '0' }}>
                                                    <tr className=''>
                                                        <th scope="col ">Job Name </th>
                                                        <th scope="col">Time Duration</th>
                                                        <th scope="col">X</th>


                                                    </tr>
                                                </thead>
                                                <tbody style={{ color: 'rgb(34, 51, 103)' }}>

                                                    {job_list.map((row) => {

                                                        return (
                                                            <tr key={row.id}  >


                                                                <td>

                                                                    {row.job ? row.job.job_name : ''}
                                                                </td>
                                                                <td >

                                                                    ({row.start ? format(row.start, 'h:mm a') : '---'} -- {row.end ? format(row.end, 'h:mm a') : '---'})
                                                                </td>


                                                                {
                                                                    user.id == row.user_id ?
                                                                        <td>
                                                                            <a style={{ cursor: 'pointer' }} className=' btn-sm bg-c' onClick={() => handleDeleteJob(row.id)}>
                                                                                <i className="fa fa-trash mr-1 text-white"></i>
                                                                            </a>
                                                                        </td>

                                                                        : ''
                                                                }





                                                            </tr>
                                                        );
                                                    }
                                                    )}


                                                </tbody>
                                            </table>


                                        </div>
                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </section>
                    )}

                </div>

            ) : (
                window.location.replace('/login')
            )}
        </div>





    );
}

export default Activity_details;     