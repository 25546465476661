import { useNavigate } from "react-router";
import Http from "../Http";
import { toast } from "react-toastify";


export default function Logout() {
 let user, userjon;
 var token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`
  };
  Http.post('/logout', null, { headers }).then(res => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.replace('/login');
    toast(res.message);

  }).catch(error => {
    // Handle error, e.g., show an error message
    toast(error);
  });


}