import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import getUser from './Getuser';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'date-fns';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import LogoLoader from './loader';
import { Link } from 'react-router-dom';

const AnyReactComponent = ({ lat, lng, htmlContent, isSelected }) => (
	<div lat={lat} lng={lng}>
		<div style={{ position: 'relative' }}>
			<img
				src={isSelected ? 'red-marker.svg' : 'blue-marker.svg'}
				alt=""
				style={{ width: '20px', height: '20px' }}
			/>
			{isSelected && (
				<div
					style={{
						position: 'absolute',
						top: '-30px',
						left: '-50%',
						backgroundColor: 'white',
						borderRadius: '5px',
						padding: '5px',
						boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
					}}
				>
					<h6
						className={isSelected ? 'text-danger' : 'text-primary'}
						style={{ color: '#3050a5', fontSize: '14px', margin: 0 }}
						dangerouslySetInnerHTML={{ __html: htmlContent }} // Render HTML content
					/>
				</div>
			)}
		</div>
	</div>
);

const Jobsite = () => {

	const [selectedLocation, setSelectedLocation] = useState(null);
	const [markerLocation, setMarkerLocation] = useState(null);

	const [searchQuery, setSearchQuery] = useState('');
	const [locationSuggestions, setLocationSuggestions] = useState([]);
	const [loading, setLoading] = useState(false); // Add loading state
	const [user] = useState(getUser());
	const [site_name, setJobsite] = useState('');
	const [latitude, setLat] = useState('');
	const [longitude, setLon] = useState('');
	const [diameter, setDia] = useState('');
	const [id, setID] = useState('');

	//loader
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		// Simulating an asynchronous operation
		setTimeout(() => {
			setIsLoading(false);
		}, 800);
	}, []);
	//loader
	const token = localStorage.getItem('token');
	const headers = {
		'Authorization': `Bearer ${token}`,
		'Content-Type': 'application/json',
	};
	const [data, setData] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [editedData, setEditedData] = useState({});
	const toggleModal = () => {
		setModalOpen(!modalOpen);
	};




	const handleMapClick = (event, text) => {
		const { lat, lng } = event;

		const selectedLocation = {
			lat: lat,
			lng: lng,
			text: text,
			htmlContent: `<span><p className="text-danger" style="color:#3050a5"><img src="loc.svg" alt="" /><strong>${text}</strong></p></span>`,
		};

		setSelectedLocation(selectedLocation);
	};

	const handleSearchInputChange = (event) => {
		const value = event.target.value;
		setSearchQuery(value);

		if (value.trim() === '') {
			setLocationSuggestions([]);
			return;
		}

		// Use Google Places Autocomplete API to get location suggestions
		const autocompleteService = new window.google.maps.places.AutocompleteService();
		autocompleteService.getPlacePredictions(
			{
				input: value,
				types: ['geocode', 'establishment'], // Include 'establishment' for places of interest
			},
			(results, status) => {
				if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
					setLocationSuggestions(results);
				}
			}
		);
	};

	const handleSearchSubmit = (event) => {
		event.preventDefault();

		if (searchQuery.trim() === '') {
			return;
		}

		// Start loading
		setLoading(true);

		// Use Google Maps Geocoding API to convert search query into coordinates
		const geocoder = new window.google.maps.Geocoder();
		geocoder.geocode({ address: searchQuery }, (results, status) => {
			if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
				const location = results[0].geometry.location;
				const { lat, lng } = location;

				const selectedLocation = {
					lat: lat(),
					lng: lng(),
					text: searchQuery,
					htmlContent: `<span><p className="text-danger" style="color:#000"><img src="loc.svg" alt="" /><strong>${searchQuery}</strong></p></span>`,
				};

				setSelectedLocation(selectedLocation);
				setMarkerLocation(selectedLocation); // S
			}

			// Stop loading
			setLoading(false);
		});
	};

	const handleSuggestionClick = (suggestion) => {
		setSearchQuery(suggestion.description);
		setLocationSuggestions([]);

		// Start loading
		setLoading(true);

		// Use Google Maps Geocoding API to convert selected suggestion into coordinates
		const geocoder = new window.google.maps.Geocoder();
		geocoder.geocode({ placeId: suggestion.place_id }, (results, status) => {
			if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
				const location = results[0].geometry.location;
				const { lat, lng } = location;

				const selectedLocation = {
					lat: lat(),
					lng: lng(),
					text: suggestion.description,
					htmlContent: `<span><h6 className="text-danger" style="color:#3050a5"><img src="loc.svg" alt="" /><strong>${suggestion.description}</strong></h6></span>`,
				};

				setSelectedLocation(selectedLocation);
				setMarkerLocation(selectedLocation); // Set the marker location

			}

			// Stop loading
			setLoading(false);
		});
	};
	useEffect(() => {

		if (selectedLocation) {
			setJobsite(selectedLocation.text || '');
			setLat(selectedLocation.lat || '');
			setLon(selectedLocation.lng || '');
		}


	}, [selectedLocation]);
	const locations = [];

	const mapOptions = {
		center: selectedLocation ? selectedLocation : { lat: 24.710903, lng: 46.6691708 },
		zoom: 18,
	};
	const handleEdit = (row) => {
		setEditedData(row);
		toggleModal();
	};
	const [record, setUsers] = useState([]);
	const [searchText, setSearchText] = useState('');
	const column = [

		{
			name: 'Job Site',
			selector: row => (<div> <i className='fa fa-map-marker mr-1' style={{ color: 'rgb(34, 51, 103)' }}></i>{row.site_name}</div>),
			sortable: true

		},
		{
			name: 'Latitude',
			selector: row => row.latitude,
			sortable: true

		},
		{
			name: 'Longitude',
			selector: row => row.longitude,
			sortable: true
		},
		{
			name: 'Diameter',
			selector: row => (<div><i className='fa fa-clock' style={{ color: 'rgb(34, 51, 103)' }}></i> {row.diameter}</div>),
			sortable: true
		},

		{
			name: 'Edit',
			selector: (row) => row.id,
			cell: (row) => (
				<a onClick={() => handleEdit(row)} className='btn-sm p-1 bg-c' style={{ color: 'rgb(48, 80, 165)' }}> <i className='fa fa-edit text-white'></i></a>
			),


		},
		{
			name: 'X',
			selector: (row) => row.id,
			cell: (row) => (
				<button onClick={() => handleDelete(row.id)} className='btn-sm  p-1  bg-c' style={{ color: 'rgb(241, 130, 38)' }}> <i className='fa fa-trash text-white '></i></button>


			),
		},
	]
	const fetechAllUsers = async () => {
		try {
			const response = await axios.get('https://api.passmh.com/api/all_job_site',
				{ headers });
			setUsers(response.data.data);
			// Handle success, e.g., show a success message or redirect
		} catch (error) {
			toast(error);
			// Handle error, e.g., display an error message
		}
	}
	useEffect(() => {
		fetechAllUsers();
	}, []);

	const handleSearch = (e) => {
		setSearchText(e.target.value);
	};
	const filteredData = record
		.filter((row) =>
			Object.values(row).some(
				(value) =>
					value &&
					typeof value === 'string' &&
					value.toLowerCase().includes(searchText.toLowerCase())
			)
		)
		.map((row) => ({ ...row }));



	//delete
	const handleDelete = async (id) => {
		try {
			await axios.delete(`https://api.passmh.com/api/delete_job/${id}`, { headers });
			// Remove the deleted task from the table
			toast.success('Job site Has Been Deleted', {
				onClose: () => window.location.reload(), // Reload the page when the toast is closed
			});
			setTimeout(() => {
				window.location.reload(); // Reload the page after a delay
			}, 1500);
		} catch (error) {
			console.error('Error deleting task:', error);
		}
	};
	//edit
	const handleSave = async (e) => {
		console.log(editedData);
		e.preventDefault();
		var ids = editedData.id;
		try {
			// Make an API call to update the data in the database
			const res = await axios.post(`https://api.passmh.com/api/update_job/${ids}`, {
				site_name: selectedLocation ? selectedLocation.text : editedData.site_name,
				latitude: selectedLocation ? selectedLocation.lat : editedData.latitude,
				longitude: selectedLocation ? selectedLocation.lng : editedData.longitude,
				diameter: editedData.diameter
			}, { headers });
			console.log(res.data);
			// Update the corresponding row in the data table
			setData((prevData) =>
				prevData.map((row) => (row.id === editedData.id ? editedData : row))
			);
			toast.success('Job site Has Been Updated', {
				onClose: () => window.location.reload(), // Reload the page when the toast is closed
			});
			setTimeout(() => {
				window.location.reload(); // Reload the page after a delay
			}, 1000);
			toggleModal();
		} catch (error) {
			toast.error('Error updating data:', error);
			// Handle error
		}
	};

	//delete



	return (

		<div className="content-wrapper bg-white">
			<section className="content mt-4">

				<div className="container-fluid">
					{user ? (

						<div>

							{user.role == 'Admin' || user.role == 'SuperAdmin' ? (
								<div>
									{isLoading ? <LogoLoader /> :

										<div>
											<div className="row " >
												<div className="col-12 " style={{ marginTop: '60px', marginBottom: '200px' }}>
													<div className='col-md-12 mt-2 mb-4 text-left'>
														<Link to='/add-job' style={{ fontSize: '18px', textDecoration: 'none' }} className=' bg-c p-2' ><i className='fa fa-plus-circle'></i> Add Job Site</Link>
													</div>
													<div className='col-md-12 mt-2 mb-4 '>

														<div className="card">
															<div className="card-header text-center" style={{ background: '#223367', color: '#fff' }}>
																<h3 className="card-title " style={{ fontSize: '20px' }}> Job Site Lists </h3>
															</div>
															{/* /.card-header */}
															<div className="card-body  " >
																<div className="card-body  text-center " >
																	<input
																		type="text"
																		placeholder="Search..."
																		value={searchText}
																		onChange={handleSearch}
																	/>
																	<DataTable
																		columns={column}
																		data={filteredData}
																		pagination
																	>

																	</DataTable>
																</div>
															</div>
															{/* /.card-body */}
														</div>
													</div>

													<Modal isOpen={modalOpen} toggle={toggleModal} size='xl'>
														<ModalHeader toggle={toggleModal}>Edit Job Site </ModalHeader>
														<ModalBody>
															<div className='row'>
																<div className='col-md-6'>
																	<div style={{ height: '370px', width: '100%' }}>
																		<div className='col-md-12 text-center'>
																			<form onSubmit={handleSearchSubmit}>
																				<input
																					className='mt-4 mb-4 p-2 w-50'
																					type="text"
																					value={searchQuery}
																					onChange={handleSearchInputChange}
																					placeholder="Search for a location"
																				/>
																				<button type="submit" className='bg-c p-2'>Search</button>
																			</form>
																		</div>

																		{loading && <p>Loading...</p>} {/* Display loading message */}
																		{locationSuggestions.length > 0 && (
																			<ul style={{ textDecoration: "none", listStyle: 'none', fontFamily: '18px' }}>
																				{locationSuggestions.map((suggestion) => (
																					<li style={{ fontSize: '20px', background: '#fff', margin: '5px', padding: '5px' }} key={suggestion.place_id} onClick={() => handleSuggestionClick(suggestion)}>
																						{suggestion.description}
																					</li>
																				))}
																			</ul>
																		)}
																		<GoogleMapReact
																			bootstrapURLKeys={{ key: 'AIzaSyDyBKY9mLTI-MXIFF8ZXFRBWuNANd3HC7c' }}
																			center={selectedLocation ? { lat: selectedLocation.lat, lng: selectedLocation.lng } : mapOptions.center}
																			zoom={mapOptions.zoom}
																			onClick={(event) => handleMapClick(event, searchQuery)}
																		>
																			{markerLocation && (
																				<AnyReactComponent
																					lat={markerLocation.lat}
																					lng={markerLocation.lng}
																					htmlContent={markerLocation.htmlContent}
																					isSelected={true}
																				/>
																			)}
																			{locations.map((location, index) => (
																				<AnyReactComponent
																					key={index}
																					lat={location.lat}
																					lng={location.lng}
																					htmlContent={location.htmlContent}
																				/>
																			))}
																		</GoogleMapReact>

																	</div>
																</div>
																<div className='col-md-6' style={{ marginTop: '60px' }}>
																	<form onSubmit={handleSave}>
																		<div className='row'>
																			<div className='col-md-12 text-left '>
																				<h4 className=' mt-4 mb-2'>Update Job Site </h4>
																				<small className='' style={{ marginBottom: '10px' }}><strong>Note:</strong> If Job Site Choose From Maps Section data will come automatically </small>

																			</div>
																			<div className='col-md-12 mt-4 text-left'>
																				<input type="text" className="form-control p-4" placeholder="Job Site Name" value={selectedLocation ? selectedLocation.text : editedData.site_name}
																					onChange={(e) =>
																						setEditedData((prevData) => ({
																							...prevData,
																							site_name: e.target.value
																						}))
																					} required />
																			</div>
																			<div className='col-md-12  mt-2 text-left'>
																				<input type="number" className="form-control p-4" placeholder="Area Diameter Exp: 2000,5000,100000 etc"
																					value={editedData.diameter}
																					onChange={(e) =>
																						setEditedData((prevData) => ({
																							...prevData,
																							diameter: e.target.value
																						}))
																					}
																					required />
																			</div>
																			<div className='col-md-12  mt-2'>
																				<input type="text" className="form-control p-4" placeholder="Latitude"
																					value={selectedLocation ? selectedLocation.lat : editedData.latitude}
																					onChange={(e) =>
																						setEditedData((prevData) => ({
																							...prevData,
																							latitude: e.target.value
																						}))
																					}
																					required />
																			</div>
																			<div className='col-md-12  mt-2'>
																				<input type="text" className="form-control p-4" placeholder="Longitude" value={selectedLocation ? selectedLocation.lng : editedData.longitude}
																					onChange={(e) =>
																						setEditedData((prevData) => ({
																							...prevData,
																							longitude: e.target.value
																						}))
																					}
																					required />
																			</div>
																		</div>
																		<div className="mt-4 ">
																			<div className='row'>
																				<div className='col-md-12'>
																					<button type="submit" className="btn btn-block text-white p-2 mt-4 " style={{ background: '#223367' }}><i className='fa fa-save'></i> Update</button>
																				</div>
																			</div>

																		</div>
																	</form>

																</div>
															</div>
														</ModalBody>
													</Modal>
												</div>
												{/* /.col */}
											</div>
										</div>
									}

								</div>

							) : (
								<div>
									<h2 style={{ margin: ' auto' }}>Welcome {user.name}</h2>
								</div>
							)}
						</div>



					) : (
						window.location.replace('login')
					)
					}
					{/* Small boxes (Stat box) */}
					<ToastContainer />

				</div >
			</section >
		</div >


	);
};

export default Jobsite;