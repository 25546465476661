
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import getUser from './Getuser';
import LogoLoader from './loader';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from 'react-router-dom';

const TimeSheet = () => {
    const [user] = useState(getUser());
    const token = localStorage.getItem('token');
    const [data, setUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [user_id, setUserID] = useState('');
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);
    const dateFormat = 'yyyy-MM-dd';
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleview = (row) => {
        toggleModal();
    };
    //owndata
    const [own_total, setOwnTotal] = useState([]);
    const [own_regular, setOwnReqular] = useState([]);
    const [own_over, setOwnOver] = useState([]);
    const [own_break, setOwnBreak] = useState([]);
    const [own_check_in, setOwnCheckIn] = useState([]);
    const [own_check_out, setOwnCheckOut] = useState([]);
    const [own_job_time, setOwnJobTime] = useState([]);
    //owndata
    const today = format(new Date(), 'd/MM/yyyy');

    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    //loader
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 800);
    }, []);
    //modal


    const handleEdit = (row) => {
        setUserID(row.id);
    };




    const handleFiltterStart = (e) => {
        setStartDate(e.target.value);

    };
    const handleFiltterEnd = (e) => {
        setEndDate(e.target.value);

    };
    const fetechAllUsers = async () => {


        try {
            const response = await axios.get('https://api.passmh.com/api/all_members_time',
                { headers });
            if (response.data.message == "all time") {
                console.log(response.data.message);
                setUsers(response.data.data);
            }
            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
        fetechAllUsers();
    }, []);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    console.log(start_date);

    const filteredData = data
        .filter((row) =>


            Object.values(row).some(
                (value) =>
                    value &&
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText.toLowerCase()),
            )
        )
        .map((row) => ({ ...row }));



    const fetechownData = async () => {
        try {
            const response = await axios.get('https://api.passmh.com/api/own_total_data',
                { headers });
            console.log(response.data);
            if (response.data.message == "time patch") {
                setOwnOver(response.data.data.overtime);
                setOwnCheckIn(response.data.data.clock_in);
                setOwnCheckOut(response.data.data.clock_out);
                setOwnBreak(response.data.data.break_time);
                setOwnJobTime(response.data.data.job_time);
            }

            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
        if (user) {
            fetechownData();
            // Set up a recurring timer to call the handleSave function every 10 seconds
            const tis = setInterval(() => {
            }, 60000); //60s or 1min check

            // Clean up the timer when the component unmounts or when the dependency array changes
            return () => {
                clearInterval(tis);
            };
        }
    }, []);

    // const [ex, seTex] = useState([
    //     { name: 'John Doe', age: 30, email: 'john.doe@example.com' },
    //     { name: 'Jane Smith', age: 25, email: 'jane.smith@example.com' },
    //     { name: 'Bob Johnson', age: 40, email: 'bob.johnson@example.com' },
    //   ]);

    const generateExcel = (row) => {
        const filteredTotalTime = row.total_time.filter(({ created_at }) => {
            const dateObj = parseISO(format(created_at, dateFormat));
            return (
                (!start_date || dateObj >= parseISO(start_date)) &&
                (!end_date || dateObj <= parseISO(end_date))
            );
        });
        const header = ['Date', 'Check In (hr) ', 'Regular time (hr)', 'Overtime (hr)', 'Break Time (hr)', 'Check Out (hr)', 'Job Time(hr)']; // Custom header column names
        const data = filteredTotalTime.map(({ created_at, clock_in, regular, overtime, break_time, clock_out, job_time }) => [

            format(created_at, ' MMMM d yyyy'),
            (clock_in / 3600).toFixed(2),
            ((clock_in - overtime) / 3600).toFixed(2),
            (overtime / 3600).toFixed(2),
            (break_time / 3600).toFixed(2),
            (clock_out / 3600).toFixed(2),
            (job_time / 3600).toFixed(2)
        ]); // Extracting data from the  array
        const headerStyle = {
            background: 'gree',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '8px',
            border: '1px solid #ddd',
        };
        const headerWithStyle = header.map((columnName) => ({ v: columnName, s: headerStyle }));
        const worksheet = XLSX.utils.aoa_to_sheet([headerWithStyle, ...data]); // Creating the worksheet with custom headers
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(excelBlob, row.name + ' hoursheet.xlsx');

        // Creating the worksheet with custom headers and styles

    };
    const downloadPDF = (row) => {
        const doc = new jsPDF();

        // Add the table header
        doc.setFontSize(12);
        if (start_date !== null && end_date !== null) {
            doc.text('TimeSheet Report For ' + row.name + '( ' + format(start_date, ' MMMM d yyyy') + ' - ' + format(end_date, ' MMMM d yyyy') + ' )', 15, 20);

        } else {
            doc.text('TimeSheet Report For ' + row.name, 15, 20);

        }

        // Add the user data as a table
        const filteredTotalTime = row.total_time.filter(({ created_at }) => {
            const dateObj = parseISO(format(created_at, dateFormat));
            return (
                (!start_date || dateObj >= parseISO(start_date)) &&
                (!end_date || dateObj <= parseISO(end_date))
            );
        });
        const header = ['Date', 'Check In (hr) ', 'Regular time (hr)', 'Overtime (hr)', 'Break Time (hr)', 'Check Out (hr)', 'Job Time(hr)'];
        const userTableOptions = {
            head: [header],
            body: filteredTotalTime.map(({ created_at, clock_in, regular, overtime, break_time, clock_out, job_time }) => [

                format(created_at, ' MMMM d yyyy'),
                (clock_in / 3600).toFixed(2),
                ((clock_in - overtime) / 3600).toFixed(2),
                (overtime / 3600).toFixed(2),
                (break_time / 3600).toFixed(2),
                (clock_out / 3600).toFixed(2),
                (job_time / 3600).toFixed(2)
            ]),
            startY: 30,
            styles: {
                fontSize: 10,
                cellPadding: 3,
                lineColor: 200,
                lineWidth: 0.5,
                fillColor: [34, 51, 103],
                textColor: 20,
                fontStyle: 'normal',
            },
            headStyles: {
                fillColor: [34, 51, 103],
                textColor: 255,
                fontStyle: 'bold',
            },
        };

        doc.autoTable(userTableOptions);
        const currentY = doc.lastAutoTable.finalY + 20;
        doc.setFontSize(12);
        if (start_date !== null && end_date !== null) {
            doc.text('Time Entry Details ' + '( ' + format(start_date, ' MMMM d yyyy') + ' - ' + format(end_date, ' MMMM d yyyy') + ' )', 15, currentY - 10);

        } else {
            doc.text('Time Entry Details', 15, currentY - 10);

        }
        const header2 = ['Date', 'Check In ', 'Check Out', 'Break Start', 'Break End'];
        const filteredTotalTime2 = row.time_saver.filter(({ created_at }) => {
            const dateObj = parseISO(format(created_at, dateFormat));
            return (
                (!start_date || dateObj >= parseISO(start_date)) &&
                (!end_date || dateObj <= parseISO(end_date))
            );
        });

        const userTableOptions2 = {
            head: [header2],
            body: filteredTotalTime2.map(({ created_at, clockIn, clockOut, break_time_start, break_time_end }) => [
                format(created_at, ' MMMM d yyyy'),
                clockIn ? format(clockIn, 'h:mm a') : '---',
                clockOut ? format(clockOut, 'h:mm a') : '---',
                break_time_start ? format(break_time_start, 'h:mm a') : '---',
                break_time_end ? format(break_time_end, 'h:mm a') : '---',

            ]),
            startY: currentY,
            styles: {
                fontSize: 10,
                cellPadding: 3,
                lineColor: 200,
                lineWidth: 0.5,
                fillColor: [34, 51, 103],
                textColor: 20,
                fontStyle: 'normal',
            },
            headStyles: {
                fillColor: [34, 51, 103],
                textColor: 255,
                fontStyle: 'bold',
            },
        };
        doc.autoTable(userTableOptions2);

        // Save the PDF
        doc.save(row.name + ' Time Sheet.pdf');
    };
    const [job_code_id, setJobID] = useState([]);
    //get jobsumarry
    const fetecjobCode = async () => {


        try {
            const response = await axios.post('https://api.passmh.com/api/get_job_code', {
                job_id: job_code_id
            },
                { headers });
            if (response.data.message == "job found") {
                console.log(response.data.data);

            }
            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
        if (job_code_id !== null) {
            fetecjobCode();
        }
    }, [job_code_id]);


    const downloadPDF_job_summary = (row) => {
        const doc = new jsPDF();

        // Add the table header
        doc.setFontSize(12);
        if (start_date !== null && end_date !== null) {
            doc.text('Job Summary For ' + row.name + '( ' + format(start_date, ' MMMM d yyyy') + ' - ' + format(end_date, ' MMMM d yyyy') + ' )', 15, 20);

        } else {
            doc.text('Job Summary   For ' + row.name, 15, 20);

        }

        // Add the user data as a table
        const filteredTotalTime = row.assign_job.filter(({ created_at }) => {
            const dateObj = parseISO(format(created_at, dateFormat));
            return (
                (!start_date || dateObj >= parseISO(start_date)) &&
                (!end_date || dateObj <= parseISO(end_date))
            );
        });
        const header = ['Date', 'Job Name', 'Start Time', 'End Time'];
        const userTableOptions = {
            head: [header],
            body: filteredTotalTime.map(({ created_at, job_id, start, end }) => [

                format(created_at, ' MMMM d yyyy'),
                job_id ? job_id : '---',
                start ? format(start, 'h:mm a') : '---',
                end ? format(end, 'h:mm a') : '---',
                job_id ? setJobID(job_id) : '---',

            ]),
            startY: 30,
            styles: {
                fontSize: 10,
                cellPadding: 3,
                lineColor: 200,
                lineWidth: 0.5,
                fillColor: [34, 51, 103],
                textColor: 20,
                fontStyle: 'normal',
            },
            headStyles: {
                fillColor: [34, 51, 103],
                textColor: 255,
                fontStyle: 'bold',
            },
        };

        doc.autoTable(userTableOptions);



        // Save the PDF
        doc.save(row.name + ' Job Summary.pdf');
    };
    return (
        <div className="content-wrapper" >
            {user ? (
                <div>
                    {isLoading ? <LogoLoader /> : (
                        <section className="content ">
                            <div className="container-fluid">
                                <div className='row mt-2'>

                                    {
                                        user.role === 'Admin' &&    user.type === 'Admin' && user.order_ref !== null ? '' :

                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                                                        <h5 className="card-title text-center" style={{ fontSize: '20px' }}> Overview ({today}) </h5>
                                                    </div>
                                                    <div className='row p-4' style={{ color: 'rgb(34, 51, 103)' }}>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>   CHECK IN HOURS</p>
                                                            <p className='mt-4 text-bold h4'> {(own_check_in / 3600).toFixed(2)} <span className='text-lowercase'>hr</span></p>

                                                        </div>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>   REGULAR HOURS</p>

                                                            <p className='mt-4 text-bold h4'>  {((own_check_in - own_over) / 3600).toFixed(2)} <span className='text-lowercase'>hr</span></p>
                                                        </div>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>      OVERTIME</p>
                                                            <p className='mt-4 text-bold h4'>  {(own_over / 3600).toFixed(2)} <span className='text-lowercase'>hr</span></p>

                                                        </div>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>      Break Time</p>

                                                            <p className='mt-4 text-bold h4'>  {(own_break / 3600).toFixed(2)}  <span className='text-lowercase'>hr</span></p>
                                                        </div>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>      Check Out Time</p>
                                                            <p className='mt-4 text-bold h4'>  {(own_check_out / 3600).toFixed(2)} <span className='text-lowercase'>hr</span></p>

                                                        </div>
                                                        <div className="col-md-4 text-uppercase pb-4 mt-4 border">
                                                            <p className='mt-2 text-bold'>     Job Time</p>
                                                            <p className='mt-4 text-bold h4'> {(own_job_time / 3600).toFixed(2)} <span className='text-lowercase'>hr</span></p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                </div>

                                <div className="row " >
                                    <div className="col-12">
                                        <div className='row text-center' style={{ color: 'rgb(34, 51, 103)' }}>
                                            <div className="col-md-3  text-uppercase pb-4 mt-2 ">

                                            </div>
                                            <div className="col-md-3 text-uppercase pb-4 mt-4 ">
                                                <span className='text-bold'> From</span>
                                                <input type='date' className='form-control' value={start_date}
                                                    onChange={handleFiltterStart} required />
                                            </div>
                                            <div className="col-md-3  text-uppercase pb-4 mt-4 ">
                                                <span className='text-bold'> To</span>

                                                <input type='date' className='form-control' value={end_date}
                                                    onChange={handleFiltterEnd} required />
                                            </div>
                                            {/* <div className="col-md-3 text-uppercase text-left pb-4 mt-4 ">

                                                    <button type='submit' className=' bg-c mt-4 p-1'>  <i className='fa fa-search'> </i></button>
                                                </div> */}
                                        </div>
                                        <div className="card">
                                            <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                                                <h5 className="card-title text-center" style={{ fontSize: '20px' }}> {user && user.role == 'SuperAdmin' || user.role == 'Admin' ?

                                                    <span>
                                                        Memebers Reports   ({data.length})
                                                    </span>
                                                    :
                                                    <span>
                                                        Reports
                                                    </span>
                                                }    </h5>
                                            </div>
                                            {/* /.card-header */}
                                            <div className="card-body table-responsive  " >
                                                <input
                                                    type="text"
                                                    placeholder="Search..." className='mb-4'
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                                {/* <DataTable
                                                    columns={column}
                                                    data={filteredData}
                                                    pagination
                                                >

                                                </DataTable> */}
                                                <table class="table text-left ml-2" style={{ fontWeight: '500' }} >
                                                    <thead style={{ background: 'rgb(234, 235, 238)', padding: '0' }}>
                                                        <tr className=''>
                                                            <th scope="col ">NAME</th>
                                                            <th scope="col ">CHECK IN HOURS</th>
                                                            <th scope="col">OVERTIME</th>

                                                            <th scope="col">REGULAR HOURS</th>
                                                            <th scope="col">BREAK TIME</th>
                                                            <th scope="col">CHECK OUT TIME</th>
                                                            <th scope="col">JOB TIME</th>
                                                            {/* <th scope="col">DETAILS</th> */}
                                                            <th scope="col">HOURS SHEET</th>
                                                            <th scope="col">TIMES SHEET</th>
                                                            <th scope="col">DETAILS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: 'rgb(34, 51, 103)' }}>

                                                        {filteredData.map((row) => {
                                                            let click_out = 0;
                                                            let overtime = 0;
                                                            let break_time = 0;
                                                            let clock_in_time = 0;
                                                            let job_time = 0;
                                                            {
                                                                row.total_time.forEach((record) => {

                                                                    if (record.user_id == row.id) {
                                                                        if (start_date !== null && end_date !== null) {
                                                                            const recordDate = format(record.created_at, dateFormat);
                                                                            if (recordDate >= start_date && recordDate <= end_date) {
                                                                                if (record.clock_in) {
                                                                                    clock_in_time += record.clock_in;
                                                                                }
                                                                                if (record.overtime) {
                                                                                    overtime += record.overtime;
                                                                                }
                                                                                if (record.break_time) {
                                                                                    break_time += record.break_time;
                                                                                }
                                                                                if (record.click_out) {
                                                                                    click_out += record.click_out;
                                                                                }
                                                                                if (record.job_time) {
                                                                                    job_time += record.job_time;
                                                                                }
                                                                            }

                                                                        } else {
                                                                            if (record.clock_in) {
                                                                                clock_in_time += record.clock_in;
                                                                            }
                                                                            if (record.overtime) {
                                                                                overtime += record.overtime;
                                                                            }
                                                                            if (record.break_time) {
                                                                                break_time += record.break_time;
                                                                            }
                                                                            if (record.click_out) {
                                                                                click_out += record.click_out;
                                                                            }
                                                                            if (record.job_time) {
                                                                                job_time += record.job_time;
                                                                            }
                                                                        }


                                                                    }

                                                                })
                                                            }
                                                            return (
                                                                <tr key={row.id}  >


                                                                    <td> {row.name}</td>
                                                                    <td >

                                                                        {

                                                                            (clock_in_time / 3600).toFixed(2) + ' hr'
                                                                        }
                                                                    </td>

                                                                    <td>

                                                                        {
                                                                            (overtime / 3600).toFixed(2) + ' hr'


                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        {

                                                                            ((clock_in_time - overtime) / 3600).toFixed(2) + ' hr'

                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {

                                                                            (break_time / 3600).toFixed(2) + ' hr'

                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            (click_out / 3600).toFixed(2) + ' hr'


                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        {

                                                                            (job_time / 3600).toFixed(2) + ' hr'

                                                                        }
                                                                    </td>
                                                                    {/* <td>

                                                                        <a className=' btn-sm mt-4 ml-2 mr-2 ' style={{ background: 'rgb(34, 51, 103)' }} onClick={() => handleview(row)}><i className='fa fa-eye ' style={{ color: '#fff', cursor: 'pointer' }} ></i></a>

                                                                        <Modal isOpen={modalOpen} toggle={toggleModal} size='xl'>
                                                                            <ModalHeader toggle={toggleModal}>
                                                                                <h5 style={{color:'rgb(34, 51, 103)',textTransform:'uppercase'}}>
                                                                                    <i className='fa fa-file'></i> Report For {row.name}
                                                                                </h5>
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                {
                                                                                    row.time_saver.map((t_time)=>
                                                                                    <p>
                                                                                        {  t_time.created_at}
                                                                                    </p>
                                                                                    )
                                                                                }

                                                                            </ModalBody>
                                                                        </Modal>

                                                                    </td> */}
                                                                    <td>
                                                                        <a onClick={() => generateExcel(row)} className=' btn-sm mt-4 ml-2 mr-2' style={{ background: 'rgb(34, 51, 103)' }}><i className='fa fa-download ' style={{ color: '#fff', cursor: 'pointer' }}></i></a>

                                                                    </td>
                                                                    <td>
                                                                        <a onClick={() => downloadPDF(row)} className=' btn-sm mt-4 ml-2 mr-2' style={{ background: 'rgb(34, 51, 103)' }}><i className='fa fa-clock ' style={{ color: '#fff', cursor: 'pointer' }}></i></a>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={`/Hours_details/${row.id}`} className=' btn-sm mt-4 ml-2 mr-2 text-white' style={{ background: 'rgb(34, 51, 103)' }}><i className='fa fa-eye ' style={{ color: '#fff', cursor: 'pointer' }}> </i>  Details</Link>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                        )}

                                                    </tbody>
                                                </table>

                                            </div>
                                            {/* /.card-body */}
                                        </div>

                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </section>
                    )}

                </div>

            ) : (
                window.location.replace('/login')
            )}
        </div>





    );
}

export default TimeSheet;     