import React, { useEffect, useState } from 'react';
import getUser from './Getuser';
import LogoLoader from './loader';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


const Message = () => {
  const [user] = useState(getUser());
  const token = localStorage.getItem('token');
  const [data, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const today = format(new Date(), 'd/MM/yyyy');
  const [searchText, setSearchText] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [message_count, setMessageCount] = useState(0);

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const fetechAllUsers = async () => {
    try {
      const response = await axios.get('https://api.passmh.com/api/all_employee',
        { headers });
      if (response.data.message === "All Members") {
        setUsers(response.data.data);
      }

      // Handle success, e.g., show a success message or redirect
    } catch (error) {
      toast(error);
      // Handle error, e.g., display an error message
    }
  }
  useEffect(() => {
    fetechAllUsers();
  }, []);
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = data && data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        typeof value === 'string' &&
        value.toLowerCase().includes(searchText.toLowerCase())
    )
  )
    .map((row) => ({ ...row }));

  const handleMessage = (row) => {

    const fetchTotalClockInOutTime = async (row) => {
      try {
        const response = await axios.post('https://api.passmh.com/api/store_inbox', {
          sender_id: user.id,
          reciver_id: row.id,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.data.message === 'Data Find') {
          toast.success('Opening Inbox', {
            onClose: () => window.location.replace(`message_details/${response.data.data.id}`), // Reload the page when the toast is closed
          });
          setTimeout(() => {
            window.location.replace(`message_details/${response.data.data.id}`); // Reload the page after a delay
          }, 1000);
        }

      } catch (error) {
        console.error(error);
      }

    };
    fetchTotalClockInOutTime(row);







    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const new_Group = () => {
    toggleModal();
  };
  const [selectedValues, setSelectedValues] = useState([]);

  const handleCheckboxChange = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };
  const fetecmessage = async () => {
    if (user) {
      try {
        const response = await axios.get('https://api.passmh.com/api/get_own_message_unseen',
          { headers });
        setMessageCount(response.data.data);
        console.log('inbox unseen',response.data.data);
        // Handle success, e.g., show a success message or redirect
      } catch (error) {
        console.log(error);
        // Handle error, e.g., display an error message
      }
    }
  }
  useEffect(() => {
    if (user) {

      fetecmessage();
    }
  }, []);
  
  return (

    <div className="content-wrapper bg-white" >
      {user ? (
        <div>
          {user.role == 'Admin' || user.role == 'Employee' ? (
            <div>
              {isLoading ? <LogoLoader /> :
                <div>
                  <section className="content " style={{ paddingTop: '50px' }}>
                    <div className="container-fluid">
                      <div className='row mt-4 mb-4'>
                        <div className="col-md-6 ">
                          <h5 className='text-left' style={{ color: 'rgb(34, 51, 103)' }}>
                            <i className='fa fa-user'></i>       All Members ({data ? data.length : ""})

                          </h5>

                          <div className='col-lg-12 mb-4 mr-0 p-0'>
                            <form action className="search-bar">
                              <input type="search" className='fa text-center form-control p-3' style={{ background: '#f4f6f9', border: 'none', fontSize: '18px' }} placeholder="Search..." value={searchText}
                                onChange={handleSearch} />
                            </form>

                          </div>
                          <div className='table-responsive mt-4'>
                            <table class="table text-left mt-4 " style={{ fontWeight: '500', border: '0px solid #fff' }} >

                              <tbody style={{ color: 'rgb(34, 51, 103)', border: '0px solid #fff' }} >
                                {filteredData && filteredData.map((user) => (

                                  <tr className='custom ' key={user.id} >

                                    <td className='text-capitalize'>
                                      {user.time_saver && user.time_saver.length !== 0 && format(user.time_saver[user.time_saver.length - 1].created_at, 'd/MM/yyyy') === today ? user.time_saver[user.time_saver.length - 1].clockIn || (user.time_saver[user.time_saver.length - 1].break_time_start !== null && user.time_saver[user.time_saver.length - 1].break_time_end !== null) ?

                                        <span classname=" border" style={{ borderRadius: '50%', background: '#ccc', padding: '8px', border: '3px solid green', textTransform: 'uppercase' }}> {user.name[0]}{user.name[1]}</span> : user.time_saver[user.time_saver.length - 1].clockOut ?
                                          <span classname=" border" style={{ borderRadius: '50%', background: '#ccc', padding: '8px', border: '3px solid rgb(228, 123, 40)', textTransform: 'uppercase' }}> {user.name[0]}{user.name[1]}</span>
                                          :
                                          (user.time_saver[user.time_saver.length - 1].break_time_start !== null && user.time_saver[user.time_saver.length - 1].break_time_end === null) ? <span classname=" border" style={{ borderRadius: '50%', background: '#ccc', padding: '8px', border: '3px solid #dc3545', textTransform: 'uppercase' }}> {user.name[0]}{user.name[1]}</span>

                                            :

                                            <span classname=" border" style={{ borderRadius: '50%', background: '#ccc', padding: '8px', textTransform: 'uppercase' }}> {user.name[0]}{user.name[1]}</span>
                                        :

                                        (
                                          <span classname=" border" style={{ borderRadius: '50%', background: '#ccc', padding: '8px', textTransform: 'uppercase' }}> {user.name[0]}{user.name[1]}</span>



                                        )}


                                      <span className="ml-4 " > {user.name}</span>
                                    </td>

                                    <td>

                                      <a className='bg-c p-2 btn-sm' onClick={() => handleMessage(user)}> <i className='fa fa-inbox'></i> <sup>{message_count && message_count.length}</sup>
                                      </a>

                                    </td>



                                  </tr>
                                ))}

                              </tbody>
                            </table>
                          </div>

                        </div>

                        <div className='col-md-6'>
                          <div className='row'>
                            <div className='col-md-6'>
                              <h5 className='text-left' style={{ color: 'rgb(34, 51, 103)' }}>
                                <i className='fa fa-users'></i>  Chat Groups

                              </h5>

                            </div>
                            <div className='col-md-6'>
                              {user && user.role === "Admin" && user.type === "Admin" ?

                                <a className='text-left btn-sm bg-c mb-1' onClick={() => new_Group()}>
                                  <i className='fa fa-plus-circle text-white'></i> New Group
                                </a>
                                : ""}


                              <Modal isOpen={modalOpen} toggle={toggleModal} size='lg'>
                                <ModalHeader toggle={toggleModal}>Add New Group </ModalHeader>
                                <ModalBody>
                                  <form>
                                    <div className='row'>
                                      <div className='col-md-8'>
                                        <div class="form-group">
                                          <input type='text' className='form-control' placeholder='Group Name' required />
                                        </div>
                                        <div class="form-group mt-4 text-capitalize">
                                          {data && data.map((item, index) => (
                                            <div key={index}>
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  value={item}
                                                  checked={selectedValues.includes(item.id)}
                                                  onChange={() => handleCheckboxChange(item.id)}
                                                />
                                                <span className='ml-2'> {item.name}</span>
                                              </label>
                                            </div>
                                          ))}

                                        </div>
                                        <button type='submit' className='btn-sm bg-c'><i className='fa fa-save'></i> Save Group</button>
                                      </div>
                                    </div> 
                                  </form>
                                </ModalBody>
                              </Modal>
                            </div>
                          </div>
                          <div className='col-lg-12 mb-4 mr-0 p-0'>
                            <form action className="search-bar">
                              <input type="search" className='fa text-center form-control p-3' style={{ background: '#f4f6f9', border: 'none', fontSize: '18px' }} placeholder="Search Group..." />
                            </form>

                          </div>
                          <div className='table-responsive mt-4'>
                            <table class="table text-left mt-4 " style={{ fontWeight: '500', border: '0px solid #fff' }} >

                              <tbody style={{ color: 'rgb(34, 51, 103)', border: '0px solid #fff' }} >

                                <tr className='custom' key={user.id} >

                                  <td>


                                    <span className="text-capitalize"  > <i className='fa fa-users' style={{ color: 'rgb(34, 51, 103)' }}> </i> PEC Engineering</span>
                                  </td>

                                  <td>

                                    <a className='bg-c p-2 btn-sm'> <i className='fa fa-inbox'></i> Chat Now </a>

                                  </td>



                                </tr>

                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>

                      {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                  </section>

                </div>


              }

            </div>
          ) : (
            <div>
              <h2 style={{ margin: ' auto' }}>Welcome {user.name}</h2>
            </div>

          )}
        </div>


      ) : (
        window.location.replace('/login')

      )
      }

      <ToastContainer />

    </div >

  );
}
export default Message;


