
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import getUser from '../Getuser';
import { useNavigate } from 'react-router';
import Http from '../../Http';
import { toast } from 'react-toastify';
import LogoLoader from '../loader';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';

const All_admin = () => {
    const [record, setUsers] = useState([]);
    const [user] = useState(getUser());
    const [searchText, setSearchText] = useState('');
    const [editData, setEditData] = useState({});
    const [initialEditData, setInitialEditData] = useState({});
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);
    const [details, setDetails] = useState(null);
    const dateFormat = 'yyyy-MM-dd';
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [user_details, setUserDetails] = useState([]);
    const [status, setStatus] = useState(null);
    const [package1, setPackageONe] = useState('');
    const [package1Month, setpackageMonth] = useState('');
    const [package1user, setPackageuser] = useState('');
    const [detials_id, setDetails_ID] = useState('');
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    const handleFiltterStart = (e) => {
        setStartDate(e.target.value);

    };
    const handleFiltterEnd = (e) => {
        setEndDate(e.target.value);

    };



    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);
    const column = [
        {
            name: 'Date',
            selector: row => format(row.created_at, ' MMM d, yyyy'),
            sortable: true
        },
        {
            name: 'Order Ref',
            selector: row => row.order_ref ? row.order_ref : '---',
            sortable: true
        },

        {
            name: 'Name',
            selector: row => row.name,
            sortable: true

        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true

        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row,
            sortable: true,
            cell: (row) => <a onClick={() => Activity(row)} style={{ cursor: 'pointer' }}>
                {
                    (row.status == '1' ? <span className='text-white small ' style={{ background: 'rgb(48, 80, 165)', borderRadius: '5px', padding: '2px' }}><input type='hidden' value={status} onChange={setStatus(false)} /> Active</span> : <span style={{ background: 'rgb(241, 130, 38)', borderRadius: '5px', padding: '2px' }} > <input type='hidden' value={status} onChange={setStatus(true)} />Inactive</span>)
                }
            </a>,
        },
        {
            name: 'Details',
            selector: (row) => row,
            cell: (row) => (
                <a onClick={() => showDetails(row)} className='btn-sm p-1  ' style={{ background: 'rgb(48, 80, 165)', cursor: 'pointer' }}> <i className='fa fa-eye' style={{ color: '#fff' }}></i></a>
            ),
        },

        {
            name: 'X',
            selector: (row) => row,
            cell: (row) => (
                <a className='btn-sm p-1 text-white' onClick={() => Delete_user(row)} style={{ background: 'rgb(241, 130, 38)', cursor: 'pointer' }}> <i className='fa fa-trash'></i></a>
            ),
        },
    ]

    const column2 = [
        {
            name: 'Date',
            selector: row => format(row.created_at, ' MMM d, yyyy'),
            sortable: true
        },


        {
            name: 'Name',
            selector: row => row.name,
            sortable: true

        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true

        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: (row) => (row.status == '1' ? <span className='text-white small' style={{ background: 'rgb(48, 80, 165)', borderRadius: '5px', padding: '2px' }}>Active</span> : <span style={{ background: 'rgb(241, 130, 38)', borderRadius: '5px', padding: '2px' }}>Inactive</span>),
        },
    ]
    const fetechAllUsers = async () => {



        try {
            const response = await axios.get('https://api.passmh.com/api/all_account_list',
                { headers });
            if (response.data.message == "All Account Lists") {
                setUsers(response.data.user);
                setEditData(response.data);
                setInitialEditData(response.data);
            }

            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
        fetechAllUsers();
    }, []);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = record
        .filter((row) =>
            Object.values(row).some(
                (value) =>
                    value &&
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText.toLowerCase())
            )
        )
        .map((row) => ({ ...row }));
    const filteredTotalTime = filteredData.filter(({ created_at }) => {
        const dateObj = parseISO(format(created_at, dateFormat));
        return (
            (!start_date || dateObj >= parseISO(start_date)) &&
            (!end_date || dateObj <= parseISO(end_date))
        );
    });
    const openEditModal = () => {
        setIsEditModalOpen(true);
    };
    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setEditData(initialEditData);
    };
    const handleEditModalChange = (e) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const toggleModal2 = () => {
        setModalOpen2(!modalOpen2);
    };
    const showDetails = (row) => {
        const Get_payment_details = async (row) => {
            try {
                const res = await axios.post('https://api.passmh.com/api/payment_details', {
                    order_ref: row.order_ref
                }, { headers });
                if (res.data.message === 'Details') {
                    setDetails(res.data.data);
                    setUserDetails(res.data.user_details);
                } else {
                    setDetails('');
                    setUserDetails('');

                }


            } catch (error) {
                toast(error);
            }
        };

        Get_payment_details(row);
        toggleModal();

    };
    const Delete_user = async (row) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'You won\'t be able to Recover  this Data!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                const res = await axios.delete(`https://api.passmh.com/api/delete_account_admin/${row.id}`, { headers });
                if (res.data.message === 'Delete') {
                    await Swal.fire(
                        'Deleted!',
                        'Your item has been deleted.',
                        'success'
                    );
                    window.location.reload(); // Reload the page
                } else {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res.data.message
                    });
                }
            }
        } catch (error) {
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Error: ${error.message}`
            });
        }
    };
    const Activity = (row) => {
        const activity_change = async (row) => {
            try {
                const res = await axios.post('https://api.passmh.com/api/super_admin_deactive_subscriber', {
                    order_ref: row.order_ref,
                    status: status,
                }, { headers });
                if (res.data.message === 'Activity Change') {
                    toast.success('Update Successfully', {
                        onClose: () => window.location.reload() // Reload the page when the toast is closed
                        // Reload the page when the toast is closed
                    });
                    setTimeout(() => {
                        window.location.reload()// Reload the page after a delay
                    }, 1500);
                } else {
                    toast.success(res.data.message, {
                        onClose: () => window.location.reload() // Reload the page when the toast is closed
                        // Reload the page when the toast is closed
                    });
                    setTimeout(() => {
                        window.location.reload()// Reload the page after a delay
                    }, 1500);
                }


            } catch (error) {
                toast(error);
            }
        };

        activity_change(row);

    };
    const Edit_plan = (id) => {
        setDetails_ID(id)
        console.log('detias',id);
        toggleModal2();

    };

    const update_plan= async(e)=>{
        e.preventDefault();

        try {
            const res = await axios.post('https://api.passmh.com/api/update_plan', {
                details_id: detials_id,
                package1: package1,
                package1user: package1user,
                package1Month: package1Month,
            }, { headers });
            console.log(res.data.message);
            if (res.data.message === 'plan details') {
                toast.success('Plan Updated', {
                    onClose: () => window.location.reload(), // Reload the page when the toast is closed
                });
                setTimeout(() => {
                    window.location.reload(); // Reload the page after a delay
                }, 1500);
            }


        } catch (error) {
            toast(error);
        }
    }
    return (
        <div className="content-wrapper bg-white" >
            {isLoading ? <LogoLoader /> :
                <div>
                    {user && user.role == 'SuperAdmin' ? (

                        <section className="content " style={{ marginTop: '10' }}>
                            <div className="container-fluid">
                                <div className="row " >
                                    <div className="col-12">
                                        <div className='row text-center' style={{ color: 'rgb(34, 51, 103)' }}>
                                            <div className="col-md-3  text-uppercase pb-4 mt-2 ">

                                            </div>
                                            <div className="col-md-3 text-uppercase pb-4 mt-4 ">
                                                <span className='text-bold'> From</span>
                                                <input type='date' className='form-control' value={start_date}
                                                    onChange={handleFiltterStart} required />
                                            </div>
                                            <div className="col-md-3  text-uppercase pb-4 mt-4 ">
                                                <span className='text-bold'> To</span>

                                                <input type='date' className='form-control' value={end_date}
                                                    onChange={handleFiltterEnd} required />
                                            </div>
                                            {/* <div className="col-md-3 text-uppercase text-left pb-4 mt-4 ">

                                    <button type='submit' className=' bg-c mt-4 p-1'>  <i className='fa fa-search'> </i></button>
                                </div> */}

                                            <Modal isOpen={modalOpen} toggle={toggleModal} size='lg'>
                                                <ModalHeader toggle={toggleModal}>Subscribe Details  </ModalHeader>
                                                <ModalBody>
                                                    <div className='col-md-12'>
                                                        <div className='card p-1  ' style={{ background: 'rgb(34, 51, 103)' }}>
                                                            <h5 className='text-center text-white'> Summary</h5>
                                                        </div>

                                                        <div className='row text-dark'>
                                                            <div className='col-md-6'>
                                                                <h4 className='mb-4 mt-4'> Package Details:</h4>
                                                                <p><strong>Start Date :</strong> {details ? format(details.created_at, 'MMMM dd, yyyy') : '---'}</p>
                                                                <p><strong>Name :</strong>
                                                                    {
                                                                        details && details.cart_description === "PackageOne" ? 'Started' :

                                                                            details && details.cart_description === "PackageTwo" ? 'Professional' :
                                                                                details && details.cart_description === "PackageThree" ? 'Enterprise' :
                                                                                    '---'}

                                                                </p>
                                                                <p><strong>Months :</strong> {details ? details.month + ' months' : '---'}</p>
                                                                <p><strong>Users :</strong> {details ? details.users : '---'}</p>
                                                                <p><strong>Expire Date :</strong> {details ? details.expired_date : '---'}</p>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <h4 className='mb-4 mt-4'> Payment Details:</h4>

                                                                <p><strong>Trasfer Ref :</strong> {details ? details.tran_ref : '---'}</p>
                                                                <p><strong>Order Ref :</strong> {details ? details.cart_id : '---'}</p>
                                                                <p><strong> Transer Type :</strong> {details ? details.tran_type : '---'}</p>
                                                                <p><strong> Amount :</strong> {details ? details.cart_amount + ' ' + details.cart_currency : '---'}</p>
                                                                <p><a className='btn-sm bg-c'  onClick={() => Edit_plan(details.id)}><i className='fa fa-edit'></i> Upgrade  Plan</a></p>


                                                                <Modal isOpen={modalOpen2} toggle={toggleModal2} size='lg'>
                                                                    <ModalHeader toggle={toggleModal2}>Upgrade The Plan  </ModalHeader>
                                                                    <ModalBody>
                                                                        <form onSubmit={update_plan}>
                                                                            <div className='row'>

                                                                                <div className='col-md-4 mt-2 mb-2'>
                                                                                    <select className='form-control' value={package1}
                                                                                        onChange={(e) => setPackageONe(e.target.value)} >
                                                                                        <option>Choose The Package</option>
                                                                                        <option value={'PackageOne'} >
                                                                                            Started
                                                                                        </option>
                                                                                        <option value={'PackageTwo'}>
                                                                                            Professional
                                                                                        </option>
                                                                                        <option value={'PackageThree'} >
                                                                                            Enterprise
                                                                                        </option>
                                                                                    </select>

                                                                                </div>
                                                                                <div className='col-md-4 mt-2 mb-2'>
                                                                                    <input type="number" value={package1user}
                                                                                        onChange={(e) => setPackageuser(e.target.value)} className="form-control" placeholder='Add Extra Users' />
                                                                                         <input type="hidden" value={detials_id}
                                                                                        onChange={(e) => setDetails_ID(e.target.value)} className="form-control" />
                                                                                </div>
                                                                                <div className='col-md-4 mt-2 mb-2'>
                                                                                    <input type="number" value={package1Month}
                                                                                        onChange={(e) => setpackageMonth(e.target.value)} className="form-control" placeholder='Add Extra Months' />
                                                                                </div>
                                                                                <button type='submit' className='bg-c mt-4' > <i className='fa fa-save'></i> Save Changes </button>
                                                                            </div>

                                                                        </form>

                                                                    </ModalBody>
                                                                </Modal>
                                                            </div>
                                                            <div className='col-md-12 mt-4 mb-4'>
                                                                <p className='text-center h5'>Total Members({user_details ? user_details.length : '0'})</p>
                                                                <DataTable
                                                                    columns={column2}
                                                                    data={user_details}
                                                                    pagination
                                                                >
                                                                </DataTable>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ModalBody>
                                            </Modal>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                                                <h5 className="card-title text-center" style={{ fontSize: '22px' }}> Subscriber  Account Lists  ({filteredTotalTime.length})</h5>
                                            </div>
                                            {/* /.card-header */}
                                            <div className="card-body  w-100 table-responsive" >
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                                <DataTable
                                                    columns={column}
                                                    data={filteredTotalTime}
                                                    pagination
                                                >

                                                </DataTable>
                                            </div>
                                            {/* /.card-body */}
                                        </div>

                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </section>
                    ) : (
                        window.location.replace('/login')
                    )}
                </div>
            }


        </div>





    );
}

export default All_admin;