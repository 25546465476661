import React from 'react';


const LogoLoader = () => {
  return (
    <div className="logo-loader">
      <img src={window.location.origin + '/passpm.png'} width={'80'} alt="Logo" className="logo"  />
    </div>
  );
};

export default LogoLoader;