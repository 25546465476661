import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Http from '../Http';
import axios from 'axios';
import { useLocation } from 'react-router';
import LogoLoader from './loader';

const Employee = () => {
    const [referal, setReferal] = useState(null);
    const [message, setMessage] = useState(null);
    const [ref_name, setRefName] = useState(null);
    const [name, userName] = useState('');
    const [phone, userPhone] = useState('');
    const [email, userEmail] = useState('');
    const [password, userErpass] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);
    const validate = (name, phone, email, password) => {
        let result = true;
        if (name === '' || name === null) {
            result = false
            toast('please enter the name');
        }
        if (phone === '' || phone === null) {
            result = false
            toast('please enter the phone');
        }
        if (email === '' || email === null) {
            result = false
            toast('please enter the email');
        }
        if (password === '' || password === null) {
            result = false
            toast('please enter the password');

        }
        return result;
    }

    const processSignup = async (e) => {
        e.preventDefault();

        const { name, phone, email, password } = e.target.elements;
        const mainUrl = 'https://api.passmh.com/api/add_employee';
        if (validate(name.value, phone.value, email.value, password.value)) {
            try {
                const response = await axios.post(mainUrl, {
                    name: name.value,
                    phone: phone.value,
                    email: email.value,
                    password: password.value,
                    ref: ref ? ref : null
                });
                if (response.data.message == 'Information Saved Successfully') {
                    // localStorage.setItem('token', response.data.token);
                    // localStorage.setItem('user', JSON.stringify(response.data.user));
                    toast.success(response.data.message, {
                        onClose: () => window.location.replace('/login'), // Reload the page when the toast is closed
                    });
                    setTimeout(() => {
                        window.location.replace('/login'); // Reload the page after a delay
                    }, 1500);


                } else {
                    toast(response.data.message);
                }
            } catch (error) {
                toast(error.message);
            }
        }
    };
    //get ref
    const location = useLocation();
    const refCode = new URLSearchParams(location.search).get('ref_code')?.replace('?', '');
    const ref = location.search.replace('?', '');
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.passmh.com/api/get_owner_ref?ref=${ref}`);
                if (response.data.message == "Invite Found") {
                    setReferal(response.data.data);
                    setRefName(response.data.ref_name)
                } else {
                    window.location.replace('/signup');
                }


            } catch (error) {
                toast(error);
            }
        };

        fetchData();
    }, []);
    return (
        <div className="content-wrapper" >
            {ref ? (
                <div>
                    {
                        isLoading ? <LogoLoader /> : (
                            <section className="content " style={{ paddingTop: '50px' }}>
                                <div className="container-fluid">
                                    <div className="row " >
                                        <div className="col-md-6 " style={{ margin: 'auto' }}>
                                            <div className="card text-center">
                                                <div className="card-header text-center " style={{ background: '#223367', color: '#fff' }}>
                                                    <h4 className=" text-center" style={{ float: 'center' }}> Join Now  </h4>
                                                </div>
                                                <div className="card-body login-card-body p-4">
                                                    <p className="login-box-msg"> <img src={window.location.origin + '/passpm.png'} width={'120'} /> </p>
                                                    <p className='h5 mb-2 text-left'>Invite From {ref_name ? ref_name : ''} </p>
                                                    <form onSubmit={processSignup} method='post' className='mt-4'>
                                                        <div className="input-group mb-3">
                                                            <input type="text" name="name" className="form-control" placeholder="Full Name" value={name} onChange={e => userName(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <div className="input-group-text">
                                                                    <span className="fas fa-user" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <input type="number" name="phone" className="form-control" placeholder="Phone" value={phone} onChange={e => userPhone(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <div className="input-group-text">
                                                                    <span className="fas fa-phone" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={e => userEmail(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <div className="input-group-text">
                                                                    <span className="fas fa-envelope" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <input type="password" name="password" className="form-control" placeholder="Password" value={password} onChange={e => userErpass(e.target.value)} />
                                                            <div className="input-group-append">
                                                                <div className="input-group-text">
                                                                    <span className="fas fa-lock" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">

                                                            {/* /.col */}
                                                            <div className="col-md-4">
                                                                <button type="submit" className="btn btn-block text-white" style={{ background: '#223367' }}><i className='fa fa-save'></i> Submit Information</button>
                                                            </div>
                                                            {/* /.col */}
                                                        </div>
                                                    </form>


                                                </div>

                                            </div>

                                        </div>
                                        {/* /.col */}
                                    </div>
                                    {/* /.row */}
                                </div>
                                {/* /.container-fluid */}

                            </section>
                        )

                    }



                </div>


            ) : (
                window.location.replace('/signup')
            )}


        </div>

    );
}
export default Employee