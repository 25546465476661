import React from "react";

const ContactAreaTwo = () => {
  return (
    <>
      {/* ========================= contact Area Two start =========================*/}
      <div className='contact-area'>
        <div className='container-fluid'>
          <div className='contact-inner-1'>
            <div className='row'>
              <div
                className='col-lg-4'
                data-aos='fade-right'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mb-0 text-center' >
                  <h6 className='sub-title' style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Pass MH Amazing Mobile App' : ' تطبيق بصمة الاحترافي للجوال'
                    }

                  </h6>
                  <p className="text-center banner-animate-img  top_image_bounce"> <img className='w-50' src={window.location.origin + '/pic6.png'} alt='img' /></p>
                </div>

              </div>
              <div
                className='col-lg-8 '
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mb-0' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { textAlign: 'left' } : { fontFamily: 'Noto Kufi Arabic', textAlign: 'right' }}>
                  <h6 className='sub-title' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { textAlign: 'left' } : { fontFamily: 'Noto Kufi Arabic', textAlign: 'right' }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Pass MH Amazing Dashboard' : ' لوحة تحكم احترافية لنظام البصمة   '
                    }

                  </h6>
                  <img className='w-100 banner-animate-img  top_image_bounce' src={window.location.origin + '/dashboard.png'} alt='img' />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*========================= contact-inner Two end =========================*/}
    </>
  );
};

export default ContactAreaTwo;
