
import axios from 'axios';
import React, { useState, useId } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import getUser from './Getuser';
import LogoLoader from './loader';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useParams, Link } from 'react-router-dom';
import { format, parseISO, getDay } from 'date-fns';

const Hours_details = () => {
  const [user] = useState(getUser());
  const [isLoading, setIsLoading] = useState(true);
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [request_user, setRequestUser] = useState(null);
  const [data, setUsers] = useState([]);
  const [job_summary, setJobSummary] = useState([]);
  const dateFormat = 'yyyy-MM-dd';
  const today = format(new Date(), 'd/MM/yyyy');

  const userId = useParams();
  const token = localStorage.getItem('token');

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };


  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);
  useEffect(() => {
    const today = new Date();
    const tenDaysAgo = new Date(today.getTime() - (10 * 24 * 60 * 60 * 1000));
    setStartDate(format(tenDaysAgo, 'yyyy-MM-dd'));
    setEndDate(format(today, 'yyyy-MM-dd'));
  }, []);
  const handleFiltterStart = (e) => {
    setStartDate(e.target.value);


  };
  const handleFiltterEnd = (e) => {

    setEndDate(e.target.value);

  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };


  const filteredData = data
    .filter((row) =>


      Object.values(row).some(
        (value) =>
          value &&
          typeof value === 'string' &&
          value.toLowerCase().includes(searchText.toLowerCase()),
      )
    )
    .filter(({ created_at }) => {
      const dateObj = parseISO(format(created_at, dateFormat));
      return (
        (!start_date || dateObj >= parseISO(start_date)) &&
        (!end_date || dateObj <= parseISO(end_date))
      );
    })

    .map((row) => ({ ...row }));

  const filteredDatajobsummary = job_summary
    .filter(({ created_at }) => {
      const dateObj = parseISO(format(created_at, dateFormat));
      return (
        (!start_date || dateObj >= parseISO(start_date)) &&
        (!end_date || dateObj <= parseISO(end_date))
      );
    })

    .map((row) => ({ ...row }));
  useEffect(() => {
    fetechAllUsers(userId);
  }, [userId]);
  const fetechAllUsers = async (userId) => {


    try {
      const response = await axios.post('https://api.passmh.com/api/get_date_id_total_time', {
        user_id: userId.id
      },
        { headers });
      if (response.data.message === "user activity hours") {
        console.log([response.data.message, response.data.data, response.data.user]);
        setRequestUser(response.data.user);
        setUsers(response.data.data);
      }
      // Handle success, e.g., show a success message or redirect
    } catch (error) {
      toast(error);
      // Handle error, e.g., display an error message
    }
  }

  useEffect(() => {
    fetechAlljob(userId);
  }, [userId]);
  const fetechAlljob = async (userId) => {


    try {
      const response = await axios.post('https://api.passmh.com/api/get_id_wise_job_summary', {
        user_id: userId.id
      },
        { headers });
      if (response.data.message === "user job summary") {
        console.log([response.data.message, response.data.data]);
        setJobSummary(response.data.data);
      }
      // Handle success, e.g., show a success message or redirect
    } catch (error) {
      toast(error);
      // Handle error, e.g., display an error message
    }
  }


  const downloadPDF = () => {
    const doc = new jsPDF();

    // Add the table header
    doc.setFontSize(12);
    if (start_date !== null && end_date !== null) {
      doc.text('Hours Report For ' + request_user.name + '( ' + format(start_date, ' MMMM d yyyy') + ' - ' + format(end_date, ' MMMM d yyyy') + ' )', 15, 20);

    } else {
      doc.text('Hours Report For ' + request_user.name, 15, 20);

    }

    // Add the user data as a table
    const filteredTotalTime = data.filter(({ created_at }) => {
      const dateObj = parseISO(format(created_at, dateFormat));
      return (
        (!start_date || dateObj >= parseISO(start_date)) &&
        (!end_date || dateObj <= parseISO(end_date))
      );
    });
    const header = ['Date', 'Check In (hr) ', 'Regular time (hr)', 'Overtime (hr)', 'Break Time (hr)', 'Check Out (hr)', 'Job Time(hr)'];
    const userTableOptions = {
      head: [header],
      body: filteredTotalTime.map(({ created_at, clock_in, regular, overtime, break_time, clock_out, job_time }) => [

        format(created_at, ' MMMM d yyyy'),
        (clock_in / 3600).toFixed(2),
        ((clock_in - overtime) / 3600).toFixed(2),
        (overtime / 3600).toFixed(2),
        (break_time / 3600).toFixed(2),
        (clock_out / 3600).toFixed(2),
        (job_time / 3600).toFixed(2)
      ]),
      startY: 30,
      styles: {
        fontSize: 10,
        cellPadding: 3,
        lineColor: 200,
        lineWidth: 0.5,
        fillColor: [34, 51, 103],
        textColor: 20,
        fontStyle: 'normal',
      },
      headStyles: {
        fillColor: [34, 51, 103],
        textColor: 255,
        fontStyle: 'bold',
      },
    };

    doc.autoTable(userTableOptions);






    // Save the PDF
    doc.save(request_user.name + ' Hour Sheet.pdf');
  };
  const downloadPDF2 = () => {
    const doc = new jsPDF();

    // Add the table header
    doc.setFontSize(12);
    if (start_date !== null && end_date !== null) {
      doc.text('Job Summary Report For ' + request_user.name + '( ' + format(start_date, ' MMMM d yyyy') + ' - ' + format(end_date, ' MMMM d yyyy') + ' )', 15, 20);

    } else {
      doc.text('Job Summary Report For ' + request_user.name, 15, 20);

    }

    // Add the user data as a table
    const filteredTotalTime = job_summary.filter(({ created_at }) => {
      const dateObj = parseISO(format(created_at, dateFormat));
      return (
        (!start_date || dateObj >= parseISO(start_date)) &&
        (!end_date || dateObj <= parseISO(end_date))
      );
    });
    const header = ['Date', 'Job Name', 'Duration'];
    const userTableOptions = {
      head: [header],
      body: filteredTotalTime.map(({ created_at, job, job_name, start, end }) => [

        format(created_at, ' MMMM d yyyy'),
        job.job_name,
        start ?  format(parseISO(start), 'h:mm a'):'' + ' - ' + end ?  format(parseISO(end), 'h:mm a'):''
      ]),
      startY: 30,
      styles: {
        fontSize: 10,
        cellPadding: 3,
        lineColor: 200,
        lineWidth: 0.5,
        fillColor: [34, 51, 103],
        textColor: 20,
        fontStyle: 'normal',
      },
      headStyles: {
        fillColor: [34, 51, 103],
        textColor: 255,
        fontStyle: 'bold',
      },
    };

    doc.autoTable(userTableOptions);



    // Save the PDF
    doc.save(request_user.name + ' Job Summary Sheet.pdf');
  };
  return (
    <div className="content-wrapper" >
      {user ? (
        <div>
          {isLoading ? <LogoLoader /> : (
            <section className="content ">
              <div className="container-fluid">
                <div className="row " >
                  <div className="col-12">
                    <div className='row text-center' style={{ color: 'rgb(34, 51, 103)' }}>
                      <div className="col-md-3  text-uppercase pb-4 mt-2 ">

                      </div>
                      <div className="col-md-3 text-uppercase pb-4 mt-4 ">
                        <span className='text-bold'> From</span>
                        <input type='date' className='form-control' value={start_date}
                          onChange={handleFiltterStart} required />
                      </div>
                      <div className="col-md-3  text-uppercase pb-4 mt-4 ">
                        <span className='text-bold'> To</span>

                        <input type='date' className='form-control' value={end_date}
                          onChange={handleFiltterEnd} required />
                      </div>
                      {/* <div className="col-md-3 text-uppercase text-left pb-4 mt-4 ">

                                                    <button type='submit' className=' bg-c mt-4 p-1'>  <i className='fa fa-search'> </i></button>
                                                </div> */}

                    </div>
                    <p>
                      Note: Default last 10 days on the table

                    </p>
                    <div className="card">
                      <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                        <h5 className="card-title text-center" style={{ fontSize: '20px' }}>

                          Hours Report For {request_user ? request_user.name : 'not found'}
                        </h5>
                      </div>
                      <div className="col-md-12 text-right" style={{ color: '#fff' }}>
                        <a onClick={() => downloadPDF()} className='btn btn-sm mt-4  text-white' style={{ background: '#223367' }}><i className='fa fa-plus'></i> Download PDF</a>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body table-responsive  " >
                        <input
                          type="text"
                          placeholder="Search..." className='mb-4'
                          value={searchText}
                          onChange={handleSearch}
                        />
                        {/* <DataTable
                                                    columns={column}
                                                    data={filteredData}
                                                    pagination
                                                >

                                                </DataTable> */}
                        <table class="table text-left ml-2" style={{ fontWeight: '500' }} >
                          <thead style={{ background: 'rgb(234, 235, 238)', padding: '0' }}>
                            <tr className=''>
                              <th scope="col ">Date</th>
                              <th scope="col ">CHECK IN HOURS</th>
                              <th scope="col">OVERTIME</th>

                              <th scope="col">REGULAR HOURS</th>
                              <th scope="col">BREAK TIME</th>
                              <th scope="col">CHECK OUT TIME</th>
                              <th scope="col">JOB TIME</th>
                              <th scope="col">DETAILS</th>
                            </tr>
                          </thead>
                          <tbody style={{ color: 'rgb(34, 51, 103)' }}>

                            {filteredData.map((row) => {

                              return (
                                <tr key={row.id}  >


                                  <td>

                                    <div className='small border text-center text-white' style={{ background: 'rgb(34, 51, 103)', borderRadius: '10px' }}>
                                      <span >

                                        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][getDay(parseISO(row.created_at))]}
                                      </span> <br />
                                      <span>
                                        {format(parseISO(row.created_at), 'MMM d')}
                                      </span>
                                    </div>
                                  </td>
                                  <td >

                                    {

                                      (row.clock_in / 3600).toFixed(2) + ' hr'
                                    }
                                  </td>

                                  <td>

                                    {
                                      (row.overtime / 3600).toFixed(2) + ' hr'


                                    }

                                  </td>
                                  <td>
                                    {

                                      ((row.clock_in - row.overtime) / 3600).toFixed(2) + ' hr'

                                    }
                                  </td>
                                  <td>
                                    {

                                      (row.break_time / 3600).toFixed(2) + ' hr'

                                    }
                                  </td>
                                  <td>
                                    {
                                      (row.clock_out / 3600).toFixed(2) + ' hr'


                                    }

                                  </td>
                                  <td>
                                    {

                                      (row.job_time / 3600).toFixed(2) + ' hr'

                                    }
                                  </td>


                                  <td>
                                    <Link to={`/activity_details/${request_user.id}/${format(row.created_at, 'yyyy-MM-dd')}`} className=' btn-sm mt-4 ml-2 mr-2 text-white' style={{ background: 'rgb(34, 51, 103)' }}><i className='fa fa-eye ' style={{ color: '#fff', cursor: 'pointer' }}> </i>  Activity</Link>


                                  </td>
                                </tr>
                              );
                            }
                            )}


                          </tbody>
                        </table>

                      </div>
                      {/* /.card-body */}
                    </div>


                    <div className="card " style={{ marginTop: '50px' }}>
                      <div className="card-header" style={{ background: '#223367', color: '#fff' }}>
                        <h5 className="card-title text-center" style={{ fontSize: '20px' }}>

                          Job Sumary
                        </h5>
                      </div>
                      <div className="col-md-12 text-right" style={{ color: '#fff' }}>
                        <a onClick={() => downloadPDF2()} className='btn btn-sm mt-4  text-white' style={{ background: '#223367' }}><i className='fa fa-plus'></i> Download PDF</a>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body table-responsive  " >

                        {/* <DataTable
                                                    columns={column}
                                                    data={filteredData}
                                                    pagination
                                                >

                                                </DataTable> */}
                        <table class="table text-left ml-2" style={{ fontWeight: '500' }} >
                          <thead style={{ background: 'rgb(234, 235, 238)', padding: '0' }}>
                            <tr className=''>
                              <th scope="col ">Date</th>
                              <th scope="col ">Job Name</th>
                              <th scope="col">Duration</th>

                            </tr>
                          </thead>
                          <tbody style={{ color: 'rgb(34, 51, 103)' }}>

                            {filteredDatajobsummary.map((row) => {

                              return (
                                <tr key={row.id}  >


                                  <td>

                                    {format(parseISO(row.created_at), 'MMMM dd, yyyy')}
                                  </td>
                                  <td >

                                    {

                                      row.job.job_name
                                    }
                                  </td>

                                  <td>
                                    ( {row.start ? format(row.start, 'h:mm a') : ''}  --
                                    {row.end ? format(row.end, 'h:mm a') : ''} )


                                  </td>



                                </tr>
                              );
                            }
                            )}


                          </tbody>
                        </table>

                      </div>
                      {/* /.card-body */}
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          )}

        </div>

      ) : (
        window.location.replace('/login')
      )}
    </div>





  );
}

export default Hours_details;     