import React, { useState, useEffect } from 'react';
import getUser from './Getuser';
import axios from 'axios';
import { toast } from 'react-toastify';
import LogoLoader from './loader';
import { FaCheck } from 'react-icons/fa';


const Current_plan = () => {
    const [user] = useState(getUser());
    const [data, setData] = useState([]);


    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);
    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    const fetechAllUsers = async () => {
        try {
            const response = await axios.get('https://api.passmh.com/api/details_plan',
                { headers });
            if (response.data.message == "Subscription Details") {
                setData(response.data.data);
            }

            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
        fetechAllUsers();
    }, [])
    return (
        <div>
            {user && user.role == "Admin" && user.type == "Admin" && user.order_ref !== null ? (
                <div>
                    {isLoading ? <LogoLoader /> :
                        <div>
                            <div className="container mb-4">
                                <div className="row " >
                                    <div className="col-md-8 " style={{ margin: 'auto' }}>
                                        <div className="card text-left p-2 mt-4">
                                            <div className="card-header text-center " style={{ background: '#223367', color: '#fff' }}>
                                                <h5 className=" text-center" style={{ float: 'center' }}> Current Subscription Details  </h5>
                                            </div>
                                            <div className='row mt-4 mb-4'>
                                                <div className='col-md-12 text-center mb-4'>
                                                    {
                                                        data && data.day_left > 0 ?
                                                            <h5>{data && data.day_left} Days Left to Expired Your Plan</h5>

                                                            : 
                                                            <h5 className='text-danger'>Your Plan Has Been Expired</h5>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <strong>Plan Name <br/> </strong> {data && data.plan}
                                                </div>
                                                <div className='col-md-4'>
                                                    <strong>
                                                        Start Date

                                                    </strong> <br/>
                                                    {data && data.start}
                                                </div>
                                                <div className='col-md-4'>
                                                    <strong>
                                                        Expired Date

                                                    </strong> <br/>
                                                    {data && data.end}
                                                </div>
                                            </div>
                                            <div className='row mt-4 mb-4'>
                                                <div className='col-md-4'>
                                                    <strong>
                                                        Invoice ID

                                                    </strong>  <br/>
                                                    {data && data.invoice} ({data && data.price} SAR)
                                                </div>
                                                <div className='col-md-4'>
                                                    <strong>
                                                        Users

                                                    </strong> <br/>
                                                    {data && data.users}
                                                </div>
                                                <div className='col-md-4'>
                                                    <strong>
                                                        Months

                                                    </strong> <br/>
                                                    {data && data.month}
                                                </div>
                                            </div>
                                            <div className='row mt-4 mb-4 text-center'>
                                                <div className='col-md-12'>
                                                    <a className=' btn btn-sm text-white' href='mailto:info@passmh.com' style={{background:'rgb(34, 51, 103)'}}><i className='fa fa-save'></i> Upgrade Plan</a>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                        </div>
                    }

                </div>

            ) : (
                window.location.replace('login')
            )
            }

        </div >


    );
}
export default Current_plan;
