import React from 'react';
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FooterTwo = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className="footer-area footer-area-2  mt-0 pd-top-120" style={{ backgroundImage: 'url("./assets/img/bg/18.png")' }}>
        <div className="container">
          <div className="row" >
            <div className="col-lg-4 col-md-6" style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl' }}>
              <div className="widget widget_about">
                <div className="thumb ">
                  <img src={window.location.origin + '/passpm.png'} width={'80px'} alt="img" />
                </div>
                <div className="details">
                  <p className='text-capitalize' style={{ color: 'black !important',fontFamily: 'Noto Kufi Arabic'  }}>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' By Our System Work time tracker that runs from any device, anytime, and anywhere Say goodbye to employee estimates. See every minute on the clock, including work hours, breaks, & Report Generation.' :'من خلال نظامنا، يمكنك تتبع وقت العمل من أي جهاز وفي أي وقت ومكان، ودّع تقديرات الموظفين غير الدقيقة، راقب كل دقيقة مسجلة، بما في ذلك ساعات العمل والاستراحات وإنشاء التقارير'
                    }
                   
                  </p>

                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 ps-xl-5"  style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl' }}>
              <div className="widget widget_nav_menu">
                <h4 className="widget-title text-white" style={{fontFamily: 'Noto Kufi Arabic'  }}>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Pay links' :'روابط سريعة'
                  }
                 
                  </h4>
                <ul style={{fontFamily: 'Noto Kufi Arabic'  }}>
                  <li>
                    <a href="/">
                      <FaChevronRight /> 
                      {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Home' :'الرئيسية'
                      }
                      
                    </a>
                  </li>
                  <li>
                    <a href="/#features">
                      <FaChevronRight />
                      {
                         localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'About Product' :'حول المنتج'
                      }
                       
                    </a>
                  </li>
                
                  <li>
                    <a  href="/#pricing">
                      <FaChevronRight />
                      {
                         localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Pricing' :'خطة التسعير'
                      }
                      
                    </a>
                  </li>
                  <li>
                    <Link to="/login">
                      <FaChevronRight />
                      {
                         localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Sign In ' :'تسجيل الدخول'
                      }
                      
                    </Link>
                  </li>
               
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl' }}>
              <div className="widget widget-recent-post" >
                <h4 className="widget-title" style={{fontFamily: 'Noto Kufi Arabic'  }}>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Contact us' :'تواصل معنا'
                  }
                 
                  </h4>
                <div className="widget widget_contact">
                  <ul className="details" style={{fontFamily: 'Noto Kufi Arabic'  }}>
                    <li>
                      <FaMapMarkedAlt />
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Al Olaya, Riyadh 12333' :' حي العليا، الرياض'
                      }
                       
                    </li>
                    <li className="mt-3">
                      <FaPhoneAlt /> 0570432020
                    </li>
                    <li className="mt-2">
                      <FaEnvelope /> info@passmh.com	
                    </li>
                  </ul>
                  <ul className="social-media mt-4">
                    <li>
                      <Link to="https://www.linkedin.com/company/tamakan-ksa/">
                        <FaLinkedinIn />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://x.com/Tamakanksa">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/tamakanksa/">
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://api.whatsapp.com/send/?phone=966570432020&text&type=phone_number&app_absent=0">
                        <FaWhatsapp />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom" style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center " style={{fontFamily: 'Noto Kufi Arabic'  }}>
                <p className='text-white' style={{ color: 'white !important' }}>
                  {
                     localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '© PassMh 2024 | All Rights Reserved' :'© PassMh 2024 | جميع الحقوق محفوظة'
                  }
                  </p>
              </div>
              <div className="col-md-6"  style={{fontFamily: 'Noto Kufi Arabic'  }}>

                <a target='_blank' href="/our_privacy_policy">
                {
                     localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Privacy Policy' :' سياسة الخصوصية'
                } 
                
                </a> 
                <Link className='mr-4 ml-4' to="mailto:info@passmh.com">
                {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Contact Us' :'تواصل معنا'
                }
                
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterTwo;
