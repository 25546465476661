import React, { useState, useEffect } from 'react';
import './App.css';
import Top from './components/Top';
import Home from './components/Home';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Layout from './layout';
import { Routes, Route, Switch } from 'react-router-dom';
import HomeTwo from './components/homepage';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.scss';
function App() {

    return (
        <div className=''>
            <Routes>
                <Route path="/" element={<HomeTwo />} />
            </Routes>

        </div>
    );
}
export default App;