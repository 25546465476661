import React, { } from 'react';

function App() {
    return (
      <div>
  {/* /.content-wrapper */}
  <footer className="main-footer">
    <strong>Copyright © 2024<a href="#"> <strong style={{color: '#3050a5'}}>Pass</strong> <strong style={{color: '#f18226'}}>MH</strong></a>.</strong>
      All rights reserved.
   
  </footer>
</div>


    );
}

export default App;