import axios from 'axios';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Http from '../Http'
import getUser from './Getuser';

import LogoLoader from './loader';

const DeleteAccount = () => {
    const [user] = useState(getUser());

    const [email, userEmail] = useState('');
    const [password, userErpass] = useState('');


    const validate = () => {
        let result = true;
        if (email === '' || email === null) {
            result = false
            // toast.warning('please enter the email');
            toast('please enter the email');
        }
        if (password === '' || password === null) {
            result = false
            toast('please enter the password');

        }
    }
    const Proccesslogin = (e) => {
        e.preventDefault();
        try {
            const response = Http.post('/delete_account', { email, password }).then((res) => {
                if (res.data.message == 'Delete') {
                    userEmail('');
                    userErpass('');
                    toast('Delete Successfully');
                } else {
                    toast(res.data.message);

                }
            });
            // Handle successful login, such as storing the token in local storage or context
        } catch (error) {
            console.log('not work');
            // Handle login error, such as displaying an error message
        }

    }
    //loction
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);
 
    return (
        <div className="content-wrapper" >
            {user ? (
                window.location.replace('/profile')


            ) : (

                <section className="content " style={{ paddingTop: '50px' }}>
                    {
                        isLoading ? <LogoLoader /> : (
                            <div className="container-fluid">
                                <div className="row " >
                                    <div className="col-md-6 " style={{ margin: 'auto' }}>
                                        <div className="card text-center">
                                            <div className="card-header text-center " style={{ background: '#223367', color: '#fff' }}>
                                                <h4 className=" text-center" style={{ float: 'center' }}> PassMH Delete User or Company  </h4>
                                            </div>
                                            <div className="card-body login-card-body p-4 ">
                                                <p className="login-box-msg"> <img src={window.location.origin + '/passpm.png'} width={'120'} /> </p>
                                                <form onSubmit={Proccesslogin} >
                                                    <div className="input-group mb-3 ">
                                                        <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={e => userEmail(e.target.value)} />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-envelope" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input type="password" name="password" className="form-control" placeholder="Password" value={password} onChange={e => userErpass(e.target.value)} />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-lock" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div className="row mb-4">

                                                        {/* /.col */}
                                                        <div className="col-md-4">
                                                            <button type="submit" className="btn btn-block text-white" style={{ background: '#223367' }}>Delete Acccount</button>
                                                        </div>
                                                        {/* /.col */}
                                                    </div>

                                                    <div className="input-group mb-3 text-capitalize ttext-left">
                                                       <strong className='mr-2'>Warning: </strong> If you Delete the User or comapny .system will release all data of the user or comapny
                                                    </div>
                                                </form>

                                             
                                            </div>

                                        </div>

                                    </div>
                                    {/* /.col */}
                                </div>
                                {/* /.row */}
                            </div>
                        )
                    }
                    {/* /.container-fluid */}
                </section>
            )}

            <ToastContainer />

        </div>

    );
}
export default DeleteAccount;