import React from 'react';
import GoogleMapReact from 'google-map-react';
{/* <span> <h6 className='text-danger'> <img src="loc.svg" alt="" />PEC Engineering</h6> </span> */ }
const AnyReactComponent = ({ lat, lng, htmlContent }) => (
    <div lat={lat} lng={lng} dangerouslySetInnerHTML={{ __html: htmlContent }} />
);

class MapContainer extends React.Component {

    render() {
        const fixedLat =24.710903; // Specify the fixed latitude
        const fixedLng = 46.6691708;
        const locations = [
            { lat: 24.710903, lng: 46.6691708, htmlContent: '<span> <h6 className="text-danger" style="color:#3050a5"> <img src="loc.svg" alt=""  />PEC Engineering</h6> </span>'},
            { lat: 24.6301364, lng: 46.5625467, htmlContent:'<span> <h6 className="text-danger" style="color:#3050a5"> <img src="loc.svg" alt="" /> <strong>شركة بانوراما للاستشارات الهندسية   </strong>   </h6> </span>' },
            { lat: 30.0168562, lng: 31.1484436, htmlContent:'<span> <h6 className="text-danger" style="color:#3050a5"> <img src="loc.svg" alt="" /> <strong>PEC Eypt Branch   </strong>   </h6> </span>' },
          ];
        return (
            <div style={{ height: '600px', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyB2lcM0abm2Dkwqg9YhQb38PHUVj5iemI0' }}
                    defaultCenter={locations[0]}
                    defaultZoom={5}

                >
                 {locations.map((location, index) => (

                    <AnyReactComponent
                       key={index}
                       lat={location.lat}
                       lng={location.lng}
                       htmlContent={location.htmlContent}
                    />
                    ))}
                </GoogleMapReact>
            </div>
        );
    }
}

export default MapContainer;