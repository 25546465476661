import React, { useState, useEffect } from 'react';
import { FaPhone, FaPlus, FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const NavbarTwo = () => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const lang =localStorage.getItem('lang');
  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  // Control sidebar navigation
  let items = document.querySelectorAll('.menu-item-has-children > a');
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector('.sub-menu')
          .classList.toggle('active');
        this.classList.toggle('open');
      };
    }
  }
  const handleEnglish = () => {
    localStorage.setItem('lang', 'english');
    setTimeout(() => {
      window.location.reload(); // Reload the page after a delay
    }, 8000);
  }
  const handleArabic = () => {
    localStorage.setItem('lang', 'arabic');

    setTimeout(() => {
      window.location.reload(); // Reload the page after a delay
    }, 1000);
  }
  return (
    <>
      {/* search popup start*/}
      <div
        className={searchShow ? 'td-search-popup active' : 'td-search-popup '}
        id="td-search-popup"
      >
        <form action="/" className="search-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? 'body-overlay active' : 'body-overlay'}
        id="body-overlay"
      ></div>
      {/* ==================== Navbar Two Start ====================*/}
      <nav className="navbar navbar-area navbar-area_2 navbar-area-2 navbar-expand-lg">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? 'menu toggle-btn d-block d-lg-none open'
                  : 'menu toggle-btn d-block d-lg-none'
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="">
            <Link to="/">
              <img src={window.location.origin + '/passpm.png'} width={'80px'} alt="img" />
            </Link>
          </div>

          <div
            className={
              active
                ? 'collapse navbar-collapse sopen'
                : 'collapse navbar-collapse'
            }
            id="itech_main_menu" 
      >
            <ul className="navbar-nav menu-open text-lg-end" style={lang && lang==='english' ? {direction:'ltr'} : {direction:'rtl'}} >
              <li >

                <a href="/" style={{ fontFamily: 'Noto Kufi Arabic' }}> {
                  lang && lang==="english" ? "Home" :'الرئيسية'
                } </a>

              </li>
              <li >
                <a href="/#features" style={{ fontFamily: 'Noto Kufi Arabic' }}> {lang && lang==="english" ? "Product Features" :'مميزات بصمة'} </a>

              </li>
              <li >
                <a href="/#industry" style={{ fontFamily: 'Noto Kufi Arabic' }}>  {lang && lang==="english" ? "Industry" :'المنشآت'}  </a>

              </li>



              <li>
                <a href="/#pricing" style={{ fontFamily: 'Noto Kufi Arabic' }}>  {lang && lang==="english" ? "Pricing" : 'التسعير'} </a>
              </li>
              <li >
                <Link to="/login" style={{ fontFamily: 'Noto Kufi Arabic' }}>  {lang && lang==="english" ? "Sign In" :'تسجيل الدخول'}</Link>

              </li>
              {
                lang && lang ==='english' ?
                  <li >
                    <Link onClick={() => handleArabic()} style={{ fontFamily: 'Noto Kufi Arabic' }}>العربية</Link>
                  </li>
               
                  :
                   lang && lang ==='arabic' ?
                    <li  >
                      <a href="" onClick={() => handleEnglish()}>English</a>

                    </li>
                    :
                    <li  >
                      <a href="" onClick={() => handleEnglish()}>English</a>

                    </li>
              }


            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop align-self-center">
            <Link className="btn btn-border-base" to="tel:+966570432020">
              0570432020 <FaPhone />
            </Link>
          </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarTwo;
