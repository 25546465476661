import React from 'react';
import { Link } from 'react-router-dom';

const ServiceAreaTwo = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative  pd-bottom-90 pt-4 " style={{ backgroundImage: 'url("./assets/img/bg/8.png")' }}>
        <div className="container" id="industry" style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
          <div className="row justify-content-center" >
            <div className="col-lg-8" >
              <div className="section-title text-center">
                <h2 className=" title mt-4 text-capitalize" style={{ fontFamily: 'Noto Kufi Arabic' }}>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                      'See how Pass MH works with your industry'
                      :
                      'اكتشف كيف يعمل نظام بصمة مع منشأتك'
                  }

                </h2>
                <h4 className="sub-title mt-4" style={{ lineHeight: '2', fontFamily: 'Noto Kufi Arabic' }} >

                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Pass MH helps all types of businesses across the world manage their employees’ time.' :
                      " نظام بصمة يساعد جميع أنواع الشركات حول العالم في إدارة وقت موظفيها"
                  }

                </h4>
              </div>
            </div>
          </div>
          <div className="row banner-animate-img  top_image_bounce">
            <div className="col-lg-4 col-md-6 " style={{ marginTop: '100px', fontFamily: 'Noto Kufi Arabic' }}>
              <div className="single-service-inner-2 " >

                <div className="details" style={{ fontFamily: 'Noto Kufi Arabic' }} >

                  <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    <Link to="#"> <i className='fa fa-building'></i>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Construction' : ' البناء'
                      }

                    </Link>
                  </h5>
                  <p>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Track your entire crew’s time and location in real-time.' :
                        'تتبع وقت وموقع فريقك بالكامل في الوقت الفعلي'
                    }

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 " style={{ marginTop: '100px' }}>
              <div className="single-service-inner-2 " >

                <div className="details" style={{ fontFamily: 'Noto Kufi Arabic' }}>

                  <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>

                    <Link to="#">  <i className='fa fa-paint-roller'></i>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Painting & Decorating' : 'الدهان والتزيين'
                      }


                    </Link>
                  </h5>
                  <p>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Time Tracking for Painting and Decorating Companies' : ' تتبع الوقت لشركات الدهان والتزيين'
                    }

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 " style={{ marginTop: '100px' }}>
              <div className="single-service-inner-2" >

                <div className="details" style={{ fontFamily: 'Noto Kufi Arabic' }}>

                  <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    <Link to="#">  <i className='fa fa-notes-medical'></i>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Healthcare & Medical' : ' الرعاية الصحية والطب  '
                      }

                    </Link>
                  </h5>
                  <p>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Time Tracking Software for Home Healthcare Providers' : ' برنامج تتبع الوقت لمقدمي الرعاية الصحية المنزلية'
                    }

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 " style={{ marginTop: '100px' }}>
              <div className="single-service-inner-2 " >

                <div className="details" style={{ fontFamily: 'Noto Kufi Arabic' }}>

                  <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    <Link to="#">
                      <i>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 29 32" version="1.1">
                          <title>BFFC0E09-B372-4154-8718-E9C1E6BA8D7ASVG</title>
                          <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g id="Atto-/-Menu---Industries" transform="translate(-242.000000, -275.000000)" stroke="#000000" strokeWidth="1.2">
                              <g id="Group-97" transform="translate(150.000000, 73.000000)">
                                <g id="Group-8" transform="translate(76.000000, 88.000000)">
                                  <g id="menu-/-industries" transform="translate(0.000000, 100.000000)">
                                    <g id="Plumbing_industry" transform="translate(30.000000, 30.000000) scale(-1, 1) translate(-30.000000, -30.000000) translate(16.000000, 14.750000)">
                                      <g id="Group" transform="translate(0.000000, 3.500000)">
                                        <path d="M14,0.6 C16.28528,0.6 18.2906764,1.79785696 19.4230537,3.59965843 L19.4230537,3.59965843 L27.4,3.59997611 L27.4,10.4000966 L19.4224,10.4013816 C18.2899046,12.202604 16.2848402,13.4 14,13.4 C11.8254614,13.4 9.904338,12.315423 8.74764896,10.6579574 C7.70242458,10.4004184 7.27604264,10.5645797 6.97615278,10.8031007 C6.59928053,11.1028506 6.4,11.5280047 6.4,12 L6.4,12 L6.4,14.4 L0.6,14.4 L0.6,9.09912109 C0.6,7.58058073 1.21551009,6.20580046 2.21065528,5.21065528 C3.20580046,4.21551009 4.58058073,3.6 6.09921259,3.59999999 L6.09921259,3.59999999 L8.57696905,3.59962215 C9.70935054,1.79784088 11.7147354,0.6 14,0.6 Z" id="Combined-Shape" fill="#FFFFFF" />
                                        <path d="M0,23.5307506 C0,21.7480687 2.81498614,18.511526 3.14656134,18.1488148 C3.23883149,18.0542559 3.36550826,18 3.49843861,18 C3.63136896,18 3.75804573,18.0542559 3.85031588,18.1488148 C4.16309434,18.5115498 7,21.7482671 7,23.5307506 C6.99843461,25.4467309 5.43296489,26.9984523 3.5,27 C1.56703511,26.9984523 0.00156139008,25.4467309 0,23.5307506 Z" id="Fill-11" fill="#FFFFFF" />
                                        <path d="M16,7 C13.790861,7 12,8.790861 12,11" id="Oval" strokeLinecap="round" strokeLinejoin="round" transform="translate(14.000000, 9.000000) rotate(-135.000000) translate(-14.000000, -9.000000) " />
                                      </g>
                                      <line x1={14} y1={4} x2={14} y2={-3} id="Path-18" strokeLinecap="round" strokeLinejoin="round" transform="translate(14.000000, 0.500000) rotate(90.000000) translate(-14.000000, -0.500000) " />
                                      <line x1={14} y1="3.5" x2={14} y2="0.5" id="Path-18" strokeLinecap="round" strokeLinejoin="round" transform="translate(14.000000, 2.000000) rotate(180.000000) translate(-14.000000, -2.000000) " />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </i>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Plumbing & Heating' : ' السباكة والتدفئة'
                      }

                    </Link>
                  </h5>
                  <p>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Time Tracking for Painting and Decorating Companies' : ' تتبع الوقت لشركات الدهان والتزيين'
                    }

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 " style={{ marginTop: '100px' }}>
              <div className="single-service-inner-2 " style={{ fontFamily: 'Noto Kufi Arabic' }} >

                <div className="details" style={{ fontFamily: 'Noto Kufi Arabic' }}>

                  <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    <Link to="#">
                      <i>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 36 33" version="1.1">
                          <title>66824B34-FF39-49AF-A3FD-677CA0C3503DSVG</title>
                          <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g id="Atto-/-Menu---Industries" transform="translate(-574.000000, -274.000000)" stroke="#000000" strokeWidth="1.2">
                              <g id="Group-97" transform="translate(150.000000, 73.000000)">
                                <g id="Group-8" transform="translate(412.000000, 88.000000)">
                                  <g id="menu-/-industries" transform="translate(0.000000, 100.000000)">
                                    <g id="Electrician_industry" transform="translate(13.000000, 14.350000)">
                                      <path d="M17,8.6 C19.3195959,8.6 21.4195959,9.54020203 22.939697,11.060303 C24.459798,12.5804041 25.4,14.6804041 25.4,17 C25.4,18.6213536 24.9408137,20.135496 24.1450312,21.4192047 C23.2580335,22.8500562 21.9533508,23.994848 20.4011725,24.6830928 L20.4011725,24.6830928 L20.4,30.3 L14,30.7 L13.5999016,24.683569 C12.0474113,23.9954472 10.7424406,22.8506225 9.85524357,21.4196479 C9.05929206,20.1358445 8.6,18.6215402 8.6,17 C8.6,14.6804041 9.54020203,12.5804041 11.060303,11.060303 C12.5804041,9.54020203 14.6804041,8.6 17,8.6 Z" id="Combined-Shape" fill="#FFFFFF" />
                                      <circle id="Combined-Shape" fill="#FFFFFF" cx={17} cy="17.5" r={1} />
                                      <g id="Group" transform="translate(-0.000000, 16.500000)" strokeLinecap="round" strokeLinejoin="round">
                                        <line x1="31.5" y1={3} x2="31.5" y2={-2} id="Path-18" transform="translate(31.500000, 0.500000) rotate(90.000000) translate(-31.500000, -0.500000) " />
                                        <line x1="2.5" y1={3} x2="2.5" y2={-2} id="Path-18" transform="translate(2.500000, 0.500000) rotate(90.000000) translate(-2.500000, -0.500000) " />
                                      </g>
                                      <g id="Group" transform="translate(6.746952, 6.746952) rotate(45.000000) translate(-6.746952, -6.746952) translate(4.246952, 6.246952)" strokeLinecap="round" strokeLinejoin="round">
                                        <line x1="2.5" y1={3} x2="2.5" y2={-2} id="Path-18" transform="translate(2.500000, 0.500000) rotate(90.000000) translate(-2.500000, -0.500000) " />
                                      </g>
                                      <g id="Group" transform="translate(17.000000, 14.600000) rotate(90.000000) translate(-17.000000, -14.600000) translate(2.400000, 11.600000)">
                                        <line x1="23.7" y1={3} x2="29.7" y2={3} id="Path-18" strokeLinecap="square" transform="translate(26.700000, 3.000000) rotate(90.000000) translate(-26.700000, -3.000000) " />
                                        <line x1="25.7" y1={3} x2="31.7" y2={3} id="Path-18" strokeLinecap="square" transform="translate(28.700000, 3.000000) rotate(90.000000) translate(-28.700000, -3.000000) " />
                                        <line x1="2.5" y1="5.5" x2="2.5" y2="0.5" id="Path-18" strokeLinecap="round" strokeLinejoin="round" transform="translate(2.500000, 3.000000) rotate(90.000000) translate(-2.500000, -3.000000) " />
                                        <line x1="22.5" y1={7} x2="22.5" y2={-1} id="Path-18" strokeLinecap="round" strokeLinejoin="round" transform="translate(22.500000, 3.000000) rotate(90.000000) translate(-22.500000, -3.000000) " />
                                      </g>
                                      <g id="Group" transform="translate(27.253048, 6.746952) rotate(135.000000) translate(-27.253048, -6.746952) translate(24.753048, 6.246952)" strokeLinecap="round" strokeLinejoin="round">
                                        <line x1="2.5" y1={3} x2="2.5" y2={-2} id="Path-18" transform="translate(2.500000, 0.500000) rotate(90.000000) translate(-2.500000, -0.500000) " />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>

                      </i>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Electrical' : ' الكهرباء'
                      }

                    </Link>
                  </h5>
                  <p>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Time Tracking for Electricians' : ' تتبع الوقت لفنيي الكهرباء'
                    }

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 " style={{ marginTop: '100px' }}>
              <div className="single-service-inner-2 " >

                <div className="details" style={{ fontFamily: 'Noto Kufi Arabic' }}>

                  <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    <Link to="#">
                      <i>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 30 32" version="1.1">
                          <title>FA5566C9-59C5-486C-94E4-20CC91F52906SVG</title>
                          <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                            <g id="Atto-/-Menu---Industries" transform="translate(-913.000000, -275.000000)" stroke="#000000" strokeWidth="1.2">
                              <g id="Group-97" transform="translate(150.000000, 73.000000)">
                                <g id="Group-8" transform="translate(748.000000, 88.000000)">
                                  <g id="menu-/-industries" transform="translate(0.000000, 100.000000)">
                                    <g id="Landscaping_industry" transform="translate(16.500000, 15.000000)">
                                      <path d="M26.7868119,0 C28.3054213,16.359997 21.7108617,23.1610832 7.00313309,20.4032586 C3.29011614,7.49791879 9.88467575,0.696832576 26.7868119,0 Z" id="Path-17" fill="#FFFFFF" />
                                      <path d="M0,30 C9.32443756,17.449998 15.3244376,10.449998 18,9" id="Path-9" />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>

                      </i>
                      {
                        localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Landscaping' : ' تنسيق الحدائق'
                      }

                    </Link>
                  </h5>
                  <p>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Time Tracking for Landscaping Companies' : ' تتبع الوقت لشركات تنسيق الحدائق '
                    }

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 " style={{ marginTop: '100px' }}>
              <div className="single-service-inner-2 ">

                <div className="details" style={{ fontFamily: 'Noto Kufi Arabic' }}>

                  <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    <Link to="#">
                      <i>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 32 29" version="1.1">
                          <title>931356E0-C22E-423B-AF7B-372E748F786DSVG</title>
                          <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g id="Atto-/-Menu---Industries" transform="translate(-240.000000, -372.000000)" stroke="#000000">
                              <g id="Group-97" transform="translate(150.000000, 73.000000)">
                                <g id="Group-8" transform="translate(76.000000, 88.000000)">
                                  <g id="menu-/-industries" transform="translate(0.000000, 200.000000)">
                                    <g id="Roofing_industry" transform="translate(15.000000, 12.000000)">
                                      <polygon id="Rectangle" strokeWidth="1.2" fill="#FFFFFF" points="23.4153226 17.7857143 23.4153226 12.1190476 27.5282258 12.1190476 27.5282258 21.8333333 30 24.0714286 30 26.5 20.9516129 26.5 20.9516129 24.8809524 15.0120968 18.8095238 9.0483871 24.8809524 9.0483871 26.5 2.72848411e-11 26.5 3.41060513e-11 24.0714286 15.0120968 9.5" />
                                      <path d="M26.8661344,4.06342439 C25.0317148,4.1031974 23.5123815,5.58223617 23.4726084,7.36694993" id="Oval" strokeWidth="1.00842881" strokeLinecap="round" strokeLinejoin="round" transform="translate(25.169371, 5.715187) rotate(-61.000000) translate(-25.169371, -5.715187) " />
                                      <path d="M28.4325336,0.662984476 C26.6358941,0.708067149 25.1428833,2.22203714 25.0978007,4.04453109" id="Oval" strokeWidth="1.00842881" strokeLinecap="round" strokeLinejoin="round" transform="translate(26.765167, 2.353758) scale(-1, 1) rotate(-37.000000) translate(-26.765167, -2.353758) " />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>

                      </i>
                      <span >
                        {
                            localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?  ' Roofing' :' تسقيف'
                        }
                         
                          </span></Link>
                  </h5>
                  <p>
                    {
                       localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?  'Time Tracking for Roofing Companies':' تتبع الوقت لشركات التسقيف'
                    }
                    
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 " style={{ marginTop: '100px' }}>
              <div className="single-service-inner-2 " >

                <div className="details" style={{ fontFamily: 'Noto Kufi Arabic' }}>

                  <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    <Link to="#">

                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 35 35" version="1.1">
                        <title>F8DC741D-3AD9-4054-8C6D-498605E3B6B2SVG</title>
                        <g id="WEB-DESIGN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                          <g id="Atto-/-Menu---Industries" transform="translate(-577.000000, -371.000000)">
                            <g id="Group-97" transform="translate(150.000000, 73.000000)">
                              <g id="Group-8" transform="translate(412.000000, 88.000000)">
                                <g id="menu-/-industries" transform="translate(0.000000, 200.000000)">
                                  <g id="Cleaning_industry" transform="translate(16.000000, 10.800000)">
                                    <g id="Group-2" transform="translate(0.000000, 5.200000)">
                                      <polygon id="Path-16" fill="#FFFFFF" points="3.73333333 14.1091471 8.81289063 11.5404297 12.7111979 7.10914714 14.6344425 1.86666667 16.1271159 7.10914714 19.7421875 11.1457682 24.2024089 13.5959961 26.1337891 14.1091471 21.8920898 15.5137044 17.3584961 19.3119792 14.4365885 25.3786458 11.2462565 18.7910807" />
                                      <g id="Group" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2">
                                        <path d="M28,14 C20.2680135,14 14,20.2680135 14,28" id="Oval" />
                                        <path d="M14,14 C6.2680135,14 0,20.2680135 0,28" id="Oval" transform="translate(7.000000, 21.000000) scale(-1, 1) translate(-7.000000, -21.000000) " />
                                        <path d="M28,0 C20.2680135,0 14,6.2680135 14,14" id="Oval" transform="translate(21.000000, 7.000000) scale(1, -1) translate(-21.000000, -7.000000) " />
                                        <path d="M14,0 C6.2680135,0 0,6.2680135 0,14" id="Oval" transform="translate(7.000000, 7.000000) scale(-1, -1) translate(-7.000000, -7.000000) " />
                                      </g>
                                    </g>
                                    <g id="Group-2" transform="translate(23.000000, 0.000000)">
                                      <polygon id="Path-16" fill="#FFFFFF" points="0.766576193 5.21644878 2.88873222 4.28164976 4.50123877 2.68569285 5.28118534 0.80488475 5.88284377 2.69819311 7.37061515 4.153448 9.22833233 5.02882409 10.0336191 5.21032629 8.29173148 5.71154738 6.41994072 7.07833063 5.18710937 9.27298452 3.85188067 6.90929767" />
                                      <g id="Group" transform="translate(0.000000, 0.200000)" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.833333333">
                                        <path d="M10,5 C7.23857625,5 5,7.23857625 5,10" id="Oval" />
                                        <path d="M5,5 C2.23857625,5 0,7.23857625 0,10" id="Oval" transform="translate(2.500000, 7.500000) scale(-1, 1) translate(-2.500000, -7.500000) " />
                                        <path d="M10,0 C7.23857625,0 5,2.23857625 5,5" id="Oval" transform="translate(7.500000, 2.500000) scale(1, -1) translate(-7.500000, -2.500000) " />
                                        <path d="M5,0 C2.23857625,0 0,2.23857625 0,5" id="Oval" transform="translate(2.500000, 2.500000) scale(-1, -1) translate(-2.500000, -2.500000) " />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                  {
                     localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Cleaning & Mainteb ' :' التنظيف والصيانة'
                  }
                      
                       </Link>
                  </h5>
                  <p>
                    {
                       localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Time Tracking for Cleaning Companies':' تتبع الوقت لشركات التنظيف'
                    }
                    
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 " style={{ marginTop: '100px' }}>
              <div className="single-service-inner-2 " >

                <div className="details" style={{ fontFamily: 'Noto Kufi Arabic' }}>

                  <h5 style={{ fontFamily: 'Noto Kufi Arabic' }}>
                    <Link to="#">
                      <i className='fa fa-laptop'></i>
                      {
                         localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Information Technology' :' تكنولوجيا المعلومات'
                      }
                      
                      </Link>
                  </h5>
                  <p>
                  {
                         localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Track  the Employee Entry  and Job Time Monthly ,Weekly,Daily' :'  تتبع دخول الموظف ووقت العمل شهريًا، أسبوعيًا، ويوميًا '
                      }
                     
                    </p>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaTwo;
