import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaTwo = () => {
  const [activeSection, setActiveSection] = useState('time');

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className='about-area pd-top-120 pd-bottom-120' >
        <div className='container'>
          <div className='row' id="features">
            <div
              className='col-lg-7 mb-4 mb-lg-0 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner banner-animate-img  top_image_bounce'>
                <img
                  className='w-100' style={{ height: '100%' }}
                  src={window.location.origin + '/mail.webp'}
                  alt='img'
                />
                <img
                  className='w-100' style={{ height: '100%' }}
                  src={window.location.origin + '/timesheet.png'}
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-5 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
              style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl' }}     >
              <div className='section-title mb-0'>
                <h6 className='sub-title' style={{ fontFamily: 'Noto Kufi Arabic' }}>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'ABOUT Product' : 'حول المنتج'
                  }

                </h6>
                <h2 className='title' style={{ fontFamily: 'Noto Kufi Arabic' }}>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? "All features, At a glance"
                      :
                      'لمحة عن المميزات'
                  }

                </h2>
                <p className='content mb-4' style={{ fontFamily: 'Noto Kufi Arabic' }}>

                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Combining everything you need to manage your team in one place.' :
                      'كل ما تحتاجه لإدارة فريقك في مكان واحد'
                  }



                </p>
                <div className='row'>

                  <div className='col-md-6' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { fontFamily: '' } : { fontFamily: 'Noto Kufi Arabic' }}>
                    <ul className='single-list-inner style-check style-heading style-check mb-3' >
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Time Tracking Features' : ' ميزات تتبع الوقت'
                        }

                      </li>

                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Location Tracking Features'

                            : ' ميزات تتبع الموقع'
                        }


                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { fontFamily: '' } : { fontFamily: 'Noto Kufi Arabic' }}>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                            ' Team Activity Features'
                            :
                            ' ميزات نشاط الفريق'
                        }

                      </li>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                            ' Timesheet Features' :
                            ' ميزات جداول الوقت'
                        }


                      </li>
                    </ul>
                  </div>
                </div>

                <div className='row' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { fontFamily: '' } : { fontFamily: 'Noto Kufi Arabic' }}>
                  <p className='content mb-4'>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                        ' Details Specification of Tracking' :
                        'مواصفات تفاصيل التتبع'
                    }

                  </p>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li  >
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Download Report' : ' تقارير التنزيلات'
                        }


                      </li>

                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Smart Reminders' :
                            ' تذكيرات ذكية'
                        }

                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Geofencing' :
                            ' نظام تحديد المواقع   '
                        }

                      </li>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Manual Time Entries'
                            :
                            ' ميزة إدخال الوقت يدوياً  '
                        }


                      </li>
                    </ul>
                  </div>

                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Break Preferences' : ' تفضيلات الاستراحة'
                        }

                      </li>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Add Notes' :
                            ' إضافة ملاحظات'
                        }

                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Job Codes' : ' رموز الوظائف'
                        }

                      </li>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Break Period' : ' فترة الاستراحة'
                        }

                      </li>
                    </ul>
                  </div>

                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Real-time  Updates' : ' تحديثات الوقت الفعلي'
                        }

                      </li>
                      <li>
                        <FaCheckCircle />

                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Clock in/out  History' : '  سجل الحضور والانصراف'
                        }

                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li >
                        <FaCheckCircle />

                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Daily Timeline' : ' الجدول الزمني اليومي'
                        }
                      </li>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Location History Tracking' : ' سجل تتبع المواقع'
                        }

                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Smart Notifications' : ' إشعارات ذكية'
                        }
                       

                      </li>
                      <li >
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Employee Status Overview' : ' نظرة عامة على حالة الموظف'
                        }

                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Departments' : ' الأقسام'
                        }

                      </li>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Filter and Search' : ' تصفية وبحث'
                        }

                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Messaging' : ' محادثات'
                        }

                      </li>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Timesheet Reports' : ' تقارير ساعات العمل'
                        }

                      </li>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                            ' Add Job Sites On Map'
                            : ' إضافة مواقع العمل على الخريطة'
                        }


                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Timesheets by Employee, Team, Job' : ' تقارير الوقت حسب الموظف، الفريق، الوظيفة'
                        }

                      </li>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?

                            ' Time Entry  History' :
                            ' سجلات إدخال الوقت'
                        }


                      </li>
                      <li>
                        <FaCheckCircle />
                        {
                          localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Managed Clock In / Out' : ' إدارة تسجيل الحضور والإنصراف'
                        }

                      </li>
                    </ul>
                  </div>

                </div>


              </div>
            </div>
          </div>

          <div style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl' }}     >
            <div className='row mt-4 mb-4 text-center ' style={{ background: 'rgb(239,237,237,1)', padding: '8px', color: '#000', fontFamily: 'Noto Kufi Arabic' }}>

              <div className='col-md-3'

                style={{
                  backgroundColor: activeSection === 'time' ? 'rgb(34,51,103,1)' : 'rgb(239, 237, 237)',
                  color: activeSection === 'time' ? 'white' : 'black',

                  padding: '10px 20px',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => handleSectionChange('time')}
              >
                <a className='h5'>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Time Tracking' : ' تتبع الوقت'
                  }

                </a>
              </div>
              <div className='col-md-3'

                style={{
                  backgroundColor: activeSection === 'gps' ? 'rgb(34,51,103,1)' : '',
                  color: activeSection === 'gps' ? 'white' : 'black',
                  padding: '10px 20px',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => handleSectionChange('gps')}
              >
                <a className='h5'>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' GPS Location Tracking'
                      :
                      ' تتبع الموقع الجغرافي'
                  }



                </a>
              </div>
              <div className='col-md-3'

                style={{
                  backgroundColor: activeSection === 'timesheet' ? 'rgb(34,51,103,1)' : '',
                  color: activeSection === 'timesheet' ? 'white' : 'black',
                  padding: '10px 20px',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => handleSectionChange('timesheet')}
              >
                <a className='h5'>
                  {localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Timesheets' : ' سجل ساعات العمل'}
                </a>
              </div >
              <div className='col-md-3'
                style={{
                  backgroundColor: activeSection === 'activity' ? 'rgb(34,51,103,1)' : '',
                  color: activeSection === 'activity' ? 'white' : 'black',
                  padding: '10px 20px',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => handleSectionChange('activity')}
              >
                <a className='h5'>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' Team Activity' : ' نشاط الفريق'
                  }


                </a>
              </div>

            </div>


          </div>
          {activeSection === 'time' && (
            <div className="row  mt-4 mb-4" >
              <div className='col-md-6 mt-4 mb-4 text-center'>
                <img src={window.location.origin + '/clockin.png'} />
              </div>
              <div className='col-md-6 mt-4 mb-4' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
                <p className='h3 mt-4 text-capitalize  ' style={{ color: 'rgb(34, 51, 103)' }}> <strong>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                      ' Work time tracker that runs from any device, anytime, and anywhere'
                      :
                      ' من خلال نظامنا، يمكنك تتبع وقت العمل من أي جهاز وفي أي وقت ومكان، ودّع تقديرات الموظفين غير الدقيقة، راقب كل دقيقة مسجلة، بما في ذلك ساعات العمل والاستراحات وإنشاء التقارير'
                  }


                </strong></p>
                <p className='h4 mt-4  text-capitalize  text-dark'>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                      '  Say goodbye to employee estimates. See every minute on the clock, including work hours, breaks , note and time-off , Site Visit , extra job or Outside Job Hours.'
                      :
                      ' وداعًا لتقديرات الموظفين، مع بصمة يمكنك الإطلاع على كل دقيقة على الساعة، بما في ذلك ساعات العمل والاستراحات والملاحظات وإجازات العمل وزيارات الموقع والوظائف الإضافية أو ساعات العمل خارج المنشأة'
                  }

                </p>
              </div>


            </div>
          )}
          {activeSection === 'gps' && (
            <div className="row  mt-4 mb-4" >

              <div className='col-md-6 mt-4 mb-4 text-center' >
                <img src={window.location.origin + '/gps.png'} />
              </div>
              <div className='col-md-6 mt-4  mb-4' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
                <p className='h1 mt-4 text-capitalize ' style={{ color: 'rgb(34, 51, 103)' }}> <strong>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                      'Be in control of your employees in the field'
                      :
                      ' كن مشرف على موظفيك داخل وخارج العمل'
                  }


                </strong></p>
                <p className='h3 mt-4  text-capitalize  text-dark'>

                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                      '  Track job routes, work history, and employee’ locations in real-time – increasing team safety and accountability.'
                      :
                      'تتبع مسارات الوظائف وتاريخ العمل ومواقع الموظفين في الوقت الفعلي - مما يزيد من سلامة الفريق والمساءلة '
                  }



                </p>
              </div>
            </div>
          )}
          {activeSection === 'timesheet' && (
            <div className="row  mt-4 mb-4">

              <div className='col-md-6 mt-4 mb-4 text-center'>
                <img className='' width='45%' src={window.location.origin + '/pic1.jpg'} />
              </div>
              <div className='col-md-6 mt-4  mb-4' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
                <p className='h1 mt-4 text-capitalize ' style={{ color: 'rgb(34, 51, 103)' }}> <strong>
                  {localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                    'Automate your employees’ timesheets' : ' نظام إدارة الوقت التلقائي'}




                </strong></p>
                <p className='h3 mt-4  text-capitalize  text-dark'>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                      'Accurate, trustworthy, and insightful timesheets app – that save hours from the payroll process.'
                      :
                      'تطبيق جداول زمنية دقيقة وموثوقة وغنية بالمعلومات – توفر ساعات من عملية الرواتب'
                  }




                </p>
              </div>
            </div>
          )}
          {activeSection === 'activity' && (
            <div className="row  mt-4 mb-4">

              <div className='col-md-6 mt-4 mb-4 text-center'>
                <img src={window.location.origin + '/activity.webp'} />
              </div>
              <div className='col-md-6 mt-4  mb-4' style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl', fontFamily: 'Noto Kufi Arabic' }}>
                <p className='h1 mt-4 text-capitalize ' style={{ color: 'rgb(34, 51, 103)' }}> <strong>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                      'Stay in the loop of work activity throughout the day'
                      :
                      'ابقَ على اطلاع بنشاط العمل طوال اليوم'
                  }

                </strong></p>
                <p className='h3 mt-4  text-capitalize  text-dark'>
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                      'Track Employee activity and location of the clock-in ,clock out ,jobsite etc' :
                      'تتبع نشاط الموظف وموقع تسجيل الحضور والإنصراف وموقع العمل، إلخ'
                  }

                </p>
              </div>

            </div>
          )}
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
