import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
const BannerTwo = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* ================== BannerTwo Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-2 bg-cover"
        style={{ backgroundImage: 'url("./assets/img/bg/8.png")' }}
      >
        <div className="container" id='homepage' >
          <div className="row" style={localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? { direction: 'ltr' } : { direction: 'rtl' }}>
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner pe-xl-5">
                <h4
                  className="subtitle text-uppercase "
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                  style={{ fontSize: '20px', fontFamily: 'Noto Kufi Arabic' }}
                >
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'Pass Mh System' : 'نظام بصمة'
                  }

                </h4>
                <h2
                  className="title  text-capitalize"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ?
                      <span>
                        Time tracking <span style={{ color: 'rgb(34, 51, 103)' }}>simplified</span>
                      </span> :
                      <span style={{ fontFamily: 'Noto Kufi Arabic' }}>
                        تتبع الوقت أصبح أسهل

                      </span>
                  }

                </h2>
                <p
                  className="content  "
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  style={{ fontSize: '20px', fontFamily: 'Noto Kufi Arabic' }}
                >
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? ' A simple, all-in-one solution that accurately tracks your employees’ time and automates timesheets' :
                      'حل بسيط وشامل يتتبع وقت موظفيك بدقة ويقوم بأتمتة جداول الوقت'
                  }


                </p>
                <Link
                  className="btn btn-border-base "
                  data-aos="fade-right"
                  data-aos-delay="300"
                  data-aos-duration="1500"
                  to="mailto:info@tamakan.com.sa"
                  style={{  fontFamily: 'Noto Kufi Arabic' }}     >
                  {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? '  Order Now ' : 'اطلب الآن'
                  }


                  <FaPlus />
                </Link>
                <div
                  className="d-inline-block align-self-center mt-4 mt-md-0"
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="1500"
                >
                  <span

                  >
                    <img src="assets/img/video.svg" alt="img" />{' '}
                    <h6 className="d-inline-block"   style={{  fontFamily: 'Noto Kufi Arabic' }}   >
                    {
                    localStorage.getItem('lang') && localStorage.getItem('lang') === 'english' ? 'how we work' : 'كيف نعمل'
                  }
                      </h6>
                  </span>

                  <div></div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-9 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >

              <div className="banner-thumb-2 mt-4 mt-lg-0">
                <img
                  className="banner-animate-img banner-animate-img-4"
                  src="assets/img/banner-2/3.png"
                  alt="img"
                />

                <div className="main-img-wrap">

                  <img
                    className="banner-animate-img banner-animate-img-2 left_image_bounce"
                    src="assets/img/banner-2/5.png"
                    alt="img"
                  />
                  <img
                    className="banner-animate-img banner-animate-img-3 top_image_bounce"
                    src={window.location.origin + '/pic3.png'}
                    alt="img"
                  />
                  <img
                    className="main-img banner-animate-img-3  top_image_bounce"
                    src={window.location.origin + '/pic6.png'}
                    alt="img"
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerTwo End ==================*/}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="XM6kTQPzzpQ"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default BannerTwo;
