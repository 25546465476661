import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import HomeTwo from './components/homepage';
import Layout from './layout';
import { Routes, Route, Navigate, Switch } from 'react-router-dom';
import './index.css';
import App from './App';
import Policy from './components/Privacy_policy';
// import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/our_privacy_policy" element={<Policy />} />
      <Route path="/*" element={
        <Layout>
         
        </Layout>
      } />
    </Routes>
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
