import { useState,useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Http from '../Http';
import axios from 'axios';
import getUser from './Getuser';
import LogoLoader from './loader';
import { useParams, Link } from 'react-router-dom';

const Signin = () => {
    const [name, userName] = useState('');
    const [user] = useState(getUser);
    const [phone, userPhone] = useState('');
    const [email, userEmail] = useState('');
    const [password, userErpass] = useState('');
    const [ref, setRef] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const userId = useParams();
    const [loading, setLoading] = useState(false); 
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);
    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);
    useEffect(() => {
        // Simulating an asynchronous operation
        setRef(userId.ref)
        console.log(userId);
    }, [userId]);
    const validate = (name, phone, email, password,ref) => {
        let result = true;
        if (ref === '' || ref === null) {
            result = false
            toast('Before signup please purchase a plan');
        }
        if (name === '' || name === null) {
            result = false
            toast('please enter the name');
        }
        if (phone === '' || phone === null) {
            result = false
            toast('please enter the phone');
        }
        if (email === '' || email === null) {
            result = false
            toast('please enter the email');
        }
        if (password === '' || password === null) {
            result = false
            toast('please enter the password');

        }
        return result;

    }

    const processSignup = async (e) => {
        e.preventDefault();
        setLoading(true);

        const { name, phone, email, password } = e.target.elements;
        const mainUrl = 'https://api.passmh.com/api/register_admin';
        console.log(mainUrl);
        if (validate(name.value, phone.value, email.value, password.value,ref)) {
            try {
                const response = await axios.post(mainUrl, {
                    name: name.value,
                    phone: phone.value,
                    email: email.value,
                    password: password.value,
                    order_ref:ref,
                });
                if (response.data.message == 'Registration Successfully') {
                    // localStorage.setItem('token', response.data.token);
                    // localStorage.setItem('user', JSON.stringify(response.data.user));
                    toast.success(response.data.message, {
                        onClose: () => window.location.replace('/login'), // Reload the page when the toast is closed
                    });
                    setTimeout(() => {
                        window.location.replace('/login'); // Reload the page after a delay
                    }, 1500);


                } else {
                    toast(response.data.message);
                }
            } catch (error) {
                toast(error.message);

                // Handle signup error, such as displaying an error message
            }
        }
    };
return (
        <div className="content-wrapper" >
            {user ? (
                window.location.replace('/profile')
            ) : (
                <section className="content " style={{ paddingTop: '50px' }}>
                    {
                         isLoading ? <LogoLoader /> : (
                            <div className="container-fluid">
                            <div className="row " >
                                <div className="col-md-6 " style={{ margin: 'auto' }}>
                                    <div className="card text-center">
                                        <div className="card-header text-center " style={{ background: '#223367', color: '#fff' }}>
                                            <h4 className=" text-center" style={{ float: 'center' }}> Registation Form  </h4>
                                        </div>
                                        <div className="card-body login-card-body p-4">
                                            <p className="login-box-msg"> <img src={window.location.origin + '/passpm.png'} width={'120'} /> </p>
                                            <form onSubmit={processSignup} method='post'>
                                                <div className="input-group mb-3">
                                                    <input type="text" name="name" className="form-control" placeholder="Full Name" value={name} onChange={e => userName(e.target.value)} />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <span className="fas fa-user" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-group mb-3">
                                                    <input type="number" name="phone" className="form-control" placeholder="Phone" value={phone} onChange={e => userPhone(e.target.value)} />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <span className="fas fa-phone" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-group mb-3">
                                                    <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={e => userEmail(e.target.value)} />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <span className="fas fa-envelope" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-group mb-3">
                                                    <input type="password" name="password" className="form-control" placeholder="Password" value={password} onChange={e => userErpass(e.target.value)} />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <span className="fas fa-lock" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
    
                                                    {/* /.col */}
                                                    <div className="col-md-4">
                                                        <button type="submit" className="btn btn-block text-white" style={{ background: '#223367' }}> {loading ? 'Please Wait...' : 'Sign Up'}</button>
                                                    </div>
                                                    {/* /.col */}
                                                </div>
                                            </form>
    
    
                                        </div>
    
                                    </div>
    
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                         )
                    }
                  
                 

                </section>
            )}
        </div>

    );
}
export default Signin