
import React, { useState, useEffect } from 'react';
import './App.css';
import Top from './components/Top';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import { Routes, Route, Switch } from 'react-router-dom';
import All_admin from './components/account_admin/All';
import Profile from './components/Profile';
import Home from './components/Home';
import Login from './components/login';
import { ToastContainer, toast } from 'react-toastify';
import Logout from './components/logout';
import getUser from './components/Getuser';
import Signin from './components/Signin';
import Jobsite from './components/Jobsite';
import Addjob from './components/Addjob';
import Department from './components/department';
import Invite from './components/invite';
import Employee from './components/employeereg';
import AllEmployee from './components/all_employee';
import Entry from './components/manual_entry';
import Break from './components/break';
import Jobcode from './components/Jobcode';
import Setting from './components/Setting';
import TimeSheet from './components/timesheet';
import DeleteAccount from './components/DeleteAccount';
import Hours_details from './components/Hours_details';
import Activity_details from './components/activity_details';
import SendLink from './components/forget_send_email';
import Code from './components/Code';
import ChangePassword from './components/ChangePassword';
import Message from './components/Message_home';
import Details from './components/Details';
import Current_plan from './components/Current_plan';

const Layout = ({ children }) => {

    return (
        <div className='App'>
            <Top />

            {/* Your header content goes here */}
            <Sidebar />



            {children}
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/Owner-Account" element={<All_admin />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/department" element={<Department />} />
                <Route path="/login" element={<Login />}></Route>
                <Route path="/logout" element={<Logout />}></Route>
                <Route path="/signup/:ref" element={<Signin />}></Route>
                <Route path="/jobsite" element={<Jobsite />}></Route>
                <Route path="/add-job" element={<Addjob />}></Route>
                <Route path="/invite" element={<Invite />}></Route>
                <Route path="/join" element={<Employee />}></Route>
                <Route path="/allmember" element={<AllEmployee />}></Route>
                <Route path="/manual_entry" element={<Entry />}></Route>
                <Route path="/break_preferences" element={<Break />}></Route>
                <Route path="/job_code" element={<Jobcode />}></Route>
                <Route path="/workweek_overtime" element={<Setting />}></Route>
                <Route path="/timesheet" element={<TimeSheet />}></Route>
                <Route path="/delete_account" element={< DeleteAccount />}></Route>
                <Route path="/Hours_details/:id" element={< Hours_details />}></Route>
                <Route path="/activity_details/:id/:date" element={< Activity_details />}></Route>
                <Route path="/reset_password" element={< SendLink />}></Route>
                <Route path="/change_password/:token/:email" element={< ChangePassword />}></Route>
                <Route path="/message" element={< Message />}> </Route>
                <Route path="/message_details/:inbox" element={< Details />}> </Route>
                <Route path="/current_plan" element={< Current_plan />}> </Route>
            </Routes>
            <Footer />

            {/* Your footer content goes here */}
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
                <div id="sidebar-overlay"></div>

            </aside>

            <ToastContainer />

        </div>
    );
};

export default Layout;