import React, { useEffect, useState } from 'react';
import getUser from './Getuser';
import LogoLoader from './loader';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { format, formatDate } from 'date-fns';
import { useParams, Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


const Details = () => {
    const [user] = useState(getUser());
    const token = localStorage.getItem('token');
    const [data, setUsers] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const today = format(new Date(), 'd/MM/yyyy');
    const [searchText, setSearchText] = useState('');
    const [sender_id, setSenderID] = useState();
    const [reciever, SetReciever] = useState();
    const [sender, setSender] = useState();
    const [reciver_id, setRecieverID] = useState();
    const [message, setMessage] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const dir = 'https://api.passmh.com';

    const inbox = useParams();
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    const headers2 = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',

    };
    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        setFile(selectedImage);
        setPreviewImage(URL.createObjectURL(selectedImage));
    };

    useEffect(() => {
        // Simulating an asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const fetechAllUsers = async () => {
        try {
            const response = await axios.post('https://api.passmh.com/api/get_inbox', {
                inbox_id: inbox.inbox
            },
                { headers });
            if (response.data.message === "Data Find") {

                setSenderID(response.data.data ? response.data.data.sender_id : '');
                setRecieverID(response.data.data ? response.data.data.reciver_id : '');
                SetReciever(response.data.data ? response.data.data.user_reciver.name : '');
                setSenderID(response.data.data ? response.data.data.user_sender.name : '');

            }

            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }
    useEffect(() => {
        fetechAllUsers();
    }, []);

    const Store_message = async (e) => {
        e.preventDefault();


        if (message !== null || message !== '') {

            try {
                const response = await axios.post('https://api.passmh.com/api/store_messages', {
                    user_id: user.id,
                    inbox_id: inbox.inbox,
                    message: message,
                }, { headers });
                if (response.data.message === 'message sent') {
                    setMessage('');
                }


                // Handle success, e.g., show a success message or redirect
            } catch (error) {
                toast(error);
                // Handle error, e.g., display an error message
            }
        }
    }


    const fetchMessage = async () => {
        try {
            const response = await axios.post('https://api.passmh.com/api/get_message_details', {
                inbox_id: inbox.inbox
            },
                { headers });
            if (response.data.message === "message details") {
                setUsers(response.data.data ? response.data.data : '');
                console.log('details', response.data.data ? response.data.data : '');
            }

            // Handle success, e.g., show a success message or redirect
        } catch (error) {
            toast(error);
            // Handle error, e.g., display an error message
        }
    }

    useEffect(() => {
        // Set up a recurring timer to call the handleSave function every 10 seconds
        const tis = setInterval(() => {
            fetchMessage();
        }, 3000); //2s check

        // Clean up the timer when the component unmounts or when the dependency array changes
        return () => {
            clearInterval(tis);
        };

    }, []);

    const Upload_file = () => {
        toggleModal();

    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (file !== null || file !== '') {
            try {
                await axios.post('https://api.passmh.com/api/store_messages', {
                    'user_id': user.id,
                    'inbox_id': inbox.inbox,
                    'image': file

                },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        }
                    });
                 console.log('File uploaded successfully!');
                 setFile('');
                 setPreviewImage('');
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        toggleModal();

    };
    return (

        <div className="content-wrapper bg-white inbox" >
            {user ? (
                <div>
                    {user.role == 'Admin' || user.role == 'Employee' ? (
                        <div>
                            {isLoading ? <LogoLoader /> :
                                <div>
                                    <section className="content " style={{ paddingTop: '50px' }}>
                                        <div className="container-fluid">
                                            {

                                                <div className="col-md-8 col-xl-8 chat" style={{ margin: 'auto' }}>
                                                    <div className="card">
                                                        <div className="card-header msg_head">
                                                            <div className="d-flex bd-highlight text-left">

                                                                <div className="user_info">
                                                                    <span className='text-capitalize'> {



                                                                    }</span>
                                                                    <h6 className='text-white'> Message({data && data.length})</h6>
                                                                </div>
                                                                {/* <div className="video_cam">
                                                                <span><i className="fas fa-video" /></span>
                                                                <span><i className="fas fa-phone" /></span>
                                                            </div> */}
                                                            </div>
                                                            <span id="action_menu_btn"><i className="fas fa-ellipsis-v" /></span>
                                                            <div className="action_menu">
                                                                <ul>
                                                                    <li><i className="fas fa-user-circle" /> View profile</li>
                                                                    <li><i className="fas fa-users" /> Add to close friends</li>
                                                                    <li><i className="fas fa-plus" /> Add to group</li>
                                                                    <li><i className="fas fa-ban" /> Block</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="card-body msg_card_body">

                                                            {
                                                                data && data.map((message) =>
                                                                    <div>
                                                                        {
                                                                            message.user_id == user.id ?

                                                                                <div>

                                                                                    <div className="d-flex justify-content-start mb-4">

                                                                                        <a class="text-uppercase" style={{ borderRadius: '50%', background: '#EEEEEE', padding: '15px 16px', color: 'rgb(34, 51, 103)', fontSize: '14px',maxHeight:'50px' }}>{ message.user.name[0]}{message.user.name[1]}</a>
                                                                                        <div className="msg_cotainer" style={{fontFamily:'"Noto Kufi Arabic", sans-serif'}}>
                                                                                            {message.message ?? 
                                                                                            
                                                                                            <a target='_blank' href={dir+message.file}>
                                                                                                <img style={{maxWidth:"150px"}} src={dir+message.file}/>
                                                                                            </a>
                                                                                            }
                                                                                            <span className="msg_time">{formatDate(message.created_at, 'h:mm a dd MMMM,yyyy')}</span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                :

                                                                                <div>

                                                                                    <div className="d-flex justify-content-end mb-4">
                                                                                        <div className="msg_cotainer_send"  style={{fontFamily:'"Noto Kufi Arabic", sans-serif'}}>
                                                                                        {message.message ?? 
                                                                                            
                                                                                            <a target='_blank' href={dir+message.file}>
                                                                                                <img style={{maxWidth:"150px"}} src={dir+message.file}/>
                                                                                            </a>
                                                                                            }

                                                                                            <span className="msg_time_send">{formatDate(message.created_at, 'h:mm a dd MMMM,yyyy')}</span>
                                                                                        </div>
                                                                                        <a class="text-uppercase" style={{ borderRadius: '50%', background: '#EEEEEE', padding: '15px 16px', color: 'rgb(34, 51, 103)', fontSize: '14px',maxHeight:'50px' }}>{message.user.name[0]}{message.user.name[1]}</a>
                                                                                    </div>
                                                                                </div>
                                                                        }

                                                                    </div>
                                                                )
                                                            }


                                                        </div>
                                                        <div className="card-footer">
                                                            <form onSubmit={Store_message}>
                                                                <div className="input-group">
                                                                    <div className="input-group-append" onClick={() => Upload_file()}>
                                                                        <span className="input-group-text attach_btn"><i className="fas fa-paperclip" /></span>
                                                                    </div>
                                                                    <textarea className="form-control type_msg" autoFocus placeholder="Type your message..." value={message} onChange={e => setMessage(e.target.value)}> </textarea>
                                                                    <div className="input-group-append">
                                                                        <button type="submit" className="input-group-text send_btn"><i className="fas fa-location-arrow" /></button>
                                                                    </div>
                                                                </div>
                                                            </form>

                                                            <Modal isOpen={modalOpen} toggle={toggleModal} size='lg'>
                                                                <ModalHeader toggle={toggleModal}><i className='fa fa-camera'></i> Attach Image </ModalHeader>
                                                                <ModalBody>
                                                                    <form onSubmit={handleSubmit}>
                                                                        <div className='row'>
                                                                            <div className="parent">
                                                                                <div className="file-upload">
                                                                                    <i className='fa fa-upload'></i>
                                                                                    <p>recommendation Image size not more 5MB</p>
                                                                                    <input type="file" onChange={handleImageChange} accept="image/*"  required/>
                                                                                    {previewImage && (
                                                                                        <div>
                                                                                            <img src={previewImage} alt="Preview" style={{ maxWidth: '200px' }} />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <button type="submit" className=' bg-c'><i className='fas fa-location-arrow'></i> Send</button>

                                                                        </div>
                                                                    </form>
                                                                </ModalBody>
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                </div>


                                            }





                                            {/* /.row */}
                                        </div>
                                        {/* /.container-fluid */}
                                    </section>

                                </div>


                            }

                        </div>
                    ) : (
                        <div>
                            <h2 style={{ margin: ' auto' }}>Welcome {user.name}</h2>
                        </div>

                    )}
                </div>


            ) : (
                window.location.replace('/login')

            )
            }

            <ToastContainer />

        </div >

    );
}
export default Details;


